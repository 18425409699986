import React from "react";
import {
  FilterButton,
  FilterButtonContainer,
  FilterButtonText,
  FilterItemContainer,
} from "../../styled/filterbar/FilterViews";

interface ButtonTypes {
  name: string;
  onClick: (params: any) => void;
}

const FilterAreaButton = ({ name, onClick }: ButtonTypes) => {
  return (
    <FilterButtonContainer onClick={onClick}>
      <i className="bi bi-person-add" />
      <FilterButtonText>{name}</FilterButtonText>
    </FilterButtonContainer>
  );
};

export default FilterAreaButton;
