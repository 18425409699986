const isAlpha = (value: any) => {
  const pattern = /^[a-zA-Z]+$/;
  return pattern.test(value);
};

const isName = (value: any) => {
  const pattern = /^([a-zA-Z ]{4,20})+$/gm;
  return pattern.test(value);
};

const isGender = (value: any) => {
  const pattern = /^([a-zA-Z]{4,6})$/gm;
  return pattern.test(value);
};

const specialCharChecker = (value: any) => {
  const pattern = /[~`!%^()={}[\]<>+]/gm;
  return pattern.test(value);
};

const isId = (value: any) => {
  const pattern = /^(\d{1,5})/gm;
  return pattern.test(value);
};

const isDob = (value: any) => {
  const pattern =
    /^((19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]))$/gm;
  return pattern.test(value);
};

const isSubscriptionDate = (value: any) => {
  const pattern =
    /^((19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3])[:]([0-4][0-9]|5[0-9])[:]([0-4][0-9]|5[1-9]))$/gm;
  return pattern.test(value);
};

const isWeight = (value: any) => {
  const pattern = /(\d*\.?\d+)$/gm;
  return pattern.test(value);
};

const isHeight = (value: any) => {
  const pattern = /^(\d{3})$/gm;
  return pattern.test(value);
};

const isStatus = (value: any) => {
  const pattern = /^([a-z]{4,5})$/gm;
  return pattern.test(value);
};

const isLimit = (value: any) => {
  const pattern = /^(\d{1,3})/gm;
  return pattern.test(value);
};

const isAmount = (value: any) => {
  const pattern = /^[+-]?([0-9]*[.])?[0-9]+$/;
  if (value < 500000) {
    return pattern.test(value);
  } else {
    return false;
  }
};

const isMonth = (value: any) => {
  const pattern = /^(0?[1-9]|1[012])$/;
  return pattern.test(value);
};

const isDate = (value: any) => {
  const pattern = /^([0-9]|31)+$/;
  return pattern.test(value);
};

const isYear = (value: any) => {
  const pattern = /^(19|20)\d{2}$/;
  return pattern.test(value);
};

const isMobileNum = (value: any) => {
  const pattern = /^\+?[9,0]?[1,0]?([0-9]{10})$/gm;
  return pattern.test(value);
};

const isValidity = (value: any) => {
  const pattern = /^(\d{1,3})$/gm;
  return pattern.test(value);
};

const isEmail = (value: any) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return String(value).toLowerCase().match(pattern);
};

const isStrongPassword = (password: any) => {
  let strength = 0;
  if (password.match(/[a-z]+/)) {
    strength += 1;
  }
  if (password.match(/[A-Z]+/)) {
    strength += 1;
  }
  if (password.match(/[0-9]+/)) {
    strength += 1;
  }
  if (password.match(/[$@#&!]+/)) {
    strength += 1;
  }

  if (password.length < 6) {
    return false;
  }

  if (password.length > 6 && strength > 2) {
    return true;
  }
};

const isIds = (value: any) => {
  const pattern = /^[0-9]?[0-9]?[0-9]?[0-9](,[0-9]?[0-9]?[0-9]?[0-9])*$/gm;
  return pattern.test(value);
};

const isImage = (value: any) => {
  const pattern = /^(image)[/](jpeg|png|JPEG|PNG)$/gm;
  return pattern.test(value);
};

const isSearch = (value: string) => {
  const pattern = /^([a-zA-Z0-9]|null)+$/gm;
  return pattern.test(value);
};

export default {
  isAlpha,
  isMobileNum,
  isEmail,
  isStrongPassword,
  isId,
  isMonth,
  isDate,
  isYear,
  isAmount,
  specialCharChecker,
  isLimit,
  isIds,
  isName,
  isGender,
  isDob,
  isWeight,
  isHeight,
  isStatus,
  isValidity,
  isSubscriptionDate,
  isImage,
  isSearch,
};
