import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const TableArea = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TableMain = styled.table`
  max-width: 100%;
  min-width: 100%;
`;

export const THead = styled.thead`
  position: sticky;
  top: 0px;
`;
export const THeadRow = styled.tr`
  border: 0px solid black;
`;

export const THeadItem = styled.th`
  text-transform: capitalize;
  padding: 15px 15px;
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const Tbody = styled.tbody`
  max-width: 100%;
  min-width: 100%;
  display: block;
`;

export const TbodyRow = styled.tr`
  background: ${appColors.offwhite};
  box-shadow: 0px 0px 2px #ddd;
  &:hover {
    border-bottom: solid 2px ${appColors.grey};
  }
`;

export const TbodyItem = styled.td`
  padding: 0px 0px;
  vertical-align: text-center;
  font-size: 18px;
  cursor: pointer;
`;
