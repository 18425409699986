export const appColors = {
  black: "#000",
  white: "#fff",
  offwhite: "#fcfcfc",
  yellow: "#fce302",
  green: "#008000",
  grey: "#ccc",
  liteGrey: "#ebd0d0",
  darkGrey: "#3b453e",
  lightRed: "c14034",
  blue: "#6b69ff",
  red: "#ff6969",
  liteGreen: "#e4f5eb",
  liteRed: "#fae3e3",
  whatsappGreen: "#44BE54",
};
