import React, { useState } from "react";
import {
  FloatingMessageButton,
  FloatingMessageButtonContainer,
  MessageButtonContainer,
  MessageButtonView,
} from "../../../../views/styled/message/MessagesViews";
import dateCompare from "../../../../utils/date/dateCompare";
interface MessageTypes {
  dataItem: any;
  onClickMessageButton: (param: any) => void;
}

export default function MessageButton({
  dataItem,
  onClickMessageButton,
}: MessageTypes) {
  const { dob } = dataItem;
  const birthday = dateCompare.isBirthday(dob);
  return (
    <>
      <FloatingMessageButtonContainer>
        <FloatingMessageButton onClick={() => onClickMessageButton(dataItem)}>
          <i className="bi bi-whatsapp" />
        </FloatingMessageButton>
      </FloatingMessageButtonContainer>
    </>
  );
}
