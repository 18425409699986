import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface AppUtilType {
  sideBarShow: boolean;
}

const initialState: AppUtilType = {
  sideBarShow: false,
};

export const appUtilSlice = createSlice({
  name: "SAppUtils",
  initialState,
  reducers: {
    setSideBarShowReducer: (state, action) => {
      state.sideBarShow = action.payload;
    },
  },
});

export const { setSideBarShowReducer } = appUtilSlice.actions;

export const appUtilState = (state: RootState) => state.appUtils;

export default appUtilSlice.reducer;
