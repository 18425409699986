import React from "react";

export default function NotFound() {
  return (
    <>
      <h2>404Not Found</h2>
      <h3>This page doesn't exists.</h3>
    </>
  );
}
