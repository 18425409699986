import React from "react";
import {
  ClickEditContainer,
  ClickEditDate,
  ClickEditError,
  ClickEditInput,
  ClickEditLabel,
  ClickEditValueContainer,
} from "../../styled/forms/FormComponents";

interface EditTypes {
  value: any;
  onChange: (e: any) => void;
  label: string;
  msg: string;
}

const ClickDate = ({ value, onChange, label, msg }: EditTypes) => {
  return (
    <ClickEditContainer>
      <ClickEditLabel>{label}</ClickEditLabel>
      <ClickEditValueContainer>
        <ClickEditDate
          type={"date"}
          placeholder={label}
          value={value}
          onChange={onChange}
        />
      </ClickEditValueContainer>
      <ClickEditError>{msg}</ClickEditError>
    </ClickEditContainer>
  );
};

export default ClickDate;
