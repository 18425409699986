import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hooks/hooks";
import { messagesUpdateRequest } from "../../../../../redux/slices/GymSlice";
import validator from "../../../../../utils/validator/validator";
import {
  ClickEditColumn,
  ClickEditContent,
  ClickEditSubmitContainer,
  SubmitButton,
} from "../../../../styled/forms/FormComponents";
import ClickEditTextArea from "../../../../components/form/ClickEditTextArea";
interface LoadingModaltypes {
  show: boolean;
  onUpdateModalClose: () => void;
}
export default function MessageEditModal({
  show,
  onUpdateModalClose,
}: LoadingModaltypes) {
  const dispatch = useAppDispatch();
  const [error, setError] = useState(true);
  const [edited, setEdited] = useState(false);
  const gyms = useAppSelector((state) => state.gyms);

  interface gymtypes {
    gymMessages: any;
  }
  const { gymMessages }: gymtypes = gyms;

  const [birthdayMessage, setBirthdayMessage] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [subscriptionReminder, setSubscriptionReminder] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [subscriptionEnded, setSubscriptionEnded] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [subscriptionPending, setSubscriptionPending] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const [subscribedPackage, setSubscribedPackage] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const [terms, setTerms] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setBirthdayMessage((prevPackageId) => {
      return { value: gymMessages?.birthday_message, msg: "", valid: true };
    });

    setSubscriptionReminder((prevPackageId) => {
      return {
        value: gymMessages?.subscription_reminder,
        msg: "",
        valid: true,
      };
    });

    setSubscriptionEnded((prevPackageId) => {
      return { value: gymMessages?.subscription_ended, msg: "", valid: true };
    });

    setSubscriptionPending((prevPackageId) => {
      return { value: gymMessages?.subscription_pending, msg: "", valid: true };
    });

    setSubscribedPackage((prevPackageId) => {
      return { value: gymMessages?.subscribed_package, msg: "", valid: true };
    });
    setTerms((prevTerms) => {
      return { value: gymMessages?.terms, msg: "", valid: true };
    });
  };

  useEffect(() => {
    if (
      terms.valid &&
      birthdayMessage.valid &&
      subscriptionReminder.valid &&
      subscriptionEnded.valid &&
      subscriptionPending.valid &&
      subscribedPackage
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [
    birthdayMessage,
    subscriptionReminder,
    subscriptionEnded,
    subscriptionPending,
    subscribedPackage,
    terms,
  ]);

  const onBirthdayMessageChange = (e: any) => {
    const value = e.target.value;
    setBirthdayMessage((prevBirthdayMessage) => {
      return {
        value,
        msg: prevBirthdayMessage.msg,
        valid: prevBirthdayMessage.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setBirthdayMessage((prevBirthdayMessage) => {
        return {
          value: prevBirthdayMessage.value,
          msg: "Invalid Birthday Message",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setBirthdayMessage((prevBirthdayMessage) => {
        return { value: prevBirthdayMessage.value, msg: "", valid: true };
      });
    }
  };

  const onSubscriptionReminderChange = (e: any) => {
    const value = e.target.value;
    setSubscriptionReminder((prevSubscriptionReminder) => {
      return {
        value,
        msg: prevSubscriptionReminder.msg,
        valid: prevSubscriptionReminder.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setSubscriptionReminder((prevSubscriptionReminder) => {
        return {
          value: prevSubscriptionReminder.value,
          msg: "Invalid Reminder Message",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setSubscriptionReminder((prevSubscriptionReminder) => {
        return { value: prevSubscriptionReminder.value, msg: "", valid: true };
      });
    }
  };

  const onSubscriptionEndedChange = (e: any) => {
    const value = e.target.value;
    setSubscriptionEnded((prevSubscriptionEnded) => {
      return {
        value,
        msg: prevSubscriptionEnded.msg,
        valid: prevSubscriptionEnded.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setSubscriptionEnded((prevSubscriptionEnded) => {
        return {
          value: prevSubscriptionEnded.value,
          msg: "Invalid Subscription Ended Message",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setSubscriptionEnded((prevSubscriptionEnded) => {
        return { value: prevSubscriptionEnded.value, msg: "", valid: true };
      });
    }
  };

  const onSubscriptionPendingChange = (e: any) => {
    const value = e.target.value;
    setSubscriptionPending((prevSubscriptionPending) => {
      return {
        value,
        msg: prevSubscriptionPending.msg,
        valid: prevSubscriptionPending.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setSubscriptionPending((prevSubscriptionPending) => {
        return {
          value: prevSubscriptionPending.value,
          msg: "Invalid Pending Message",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setSubscriptionPending((prevSubscriptionPending) => {
        return { value: prevSubscriptionPending.value, msg: "", valid: true };
      });
    }
  };

  const onSubscribedPackageChange = (e: any) => {
    const value = e.target.value;
    setSubscribedPackage((prevSubscribedPackage) => {
      return {
        value,
        msg: prevSubscribedPackage.msg,
        valid: prevSubscribedPackage.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setSubscribedPackage((prevSubscribedPackage) => {
        return {
          value: prevSubscribedPackage.value,
          msg: "Invalid Pending Message",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setSubscribedPackage((prevSubscribedPackage) => {
        return { value: prevSubscribedPackage.value, msg: "", valid: true };
      });
    }
  };

  const onTermsChange = (e: any) => {
    const value = e.target.value;
    setTerms((prevTerms) => {
      return {
        value,
        msg: prevTerms.msg,
        valid: prevTerms.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setTerms((prevTerms) => {
        return {
          value: prevTerms.value,
          msg: "Invalid Terms & Condition",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setTerms((prevTerms) => {
        return { value: prevTerms.value, msg: "", valid: true };
      });
    }
  };

  const onClose = () => {
    setEdited(false);
    setInitialValues();
    onUpdateModalClose();
  };

  const onSubmit = () => {
    if (!error) {
      const messageData: object = {
        id_gym_messages: gymMessages?.id_gym_messages.toString(),
        birthday_message: birthdayMessage.value,
        subscription_reminder: subscriptionReminder.value,
        subscription_ended: subscriptionEnded.value,
        subscription_pending: subscriptionPending.value,
        subscribed_package: subscribedPackage.value,
        terms: terms.value,
        status: "true",
      };

      dispatch(messagesUpdateRequest(messageData));
    } else {
      alert("wrong input");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Messages</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Add _subscription_end_ for subscription end date.</li>

          <li>Add _subscribed_date_ for subscription date.</li>

          <li>Add _client_name_ for client name.</li>

          <li>Add _package_name_ for package name.</li>

          <li>Add _gym_name_ for gym name.</li>
        </ul>

        <ClickEditColumn>
          <ClickEditContent>
            <ClickEditTextArea
              label="Birthday Greeting"
              value={birthdayMessage.value}
              onChange={onBirthdayMessageChange}
              msg={birthdayMessage.msg}
            />
          </ClickEditContent>

          <ClickEditContent>
            <ClickEditTextArea
              label="Subscription Reminder"
              value={subscriptionReminder.value}
              onChange={onSubscriptionReminderChange}
              msg={subscriptionReminder.msg}
            />
          </ClickEditContent>
        </ClickEditColumn>

        <ClickEditColumn>
          <ClickEditContent>
            <ClickEditTextArea
              label="Subscription Ended"
              value={subscriptionEnded.value}
              onChange={onSubscriptionEndedChange}
              msg={subscriptionEnded.msg}
            />
          </ClickEditContent>

          <ClickEditContent>
            <ClickEditTextArea
              label="Subscription Pending"
              value={subscriptionPending.value}
              onChange={onSubscriptionPendingChange}
              msg={subscriptionPending.msg}
            />
          </ClickEditContent>
        </ClickEditColumn>

        <ClickEditColumn>
          <ClickEditContent>
            <ClickEditTextArea
              label="New Subscription"
              value={subscribedPackage.value}
              onChange={onSubscribedPackageChange}
              msg={subscribedPackage.msg}
            />
          </ClickEditContent>

          <ClickEditContent>
            <ClickEditTextArea
              label="Terms & Conditions"
              value={terms.value}
              onChange={onTermsChange}
              msg={terms.msg}
            />
          </ClickEditContent>
        </ClickEditColumn>

        {edited && !error && (
          <ClickEditSubmitContainer>
            <SubmitButton onClick={onSubmit}>Update Messages</SubmitButton>
          </ClickEditSubmitContainer>
        )}
      </Modal.Body>
    </Modal>
  );
}
