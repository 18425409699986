import React, { useState } from "react";
import {
  DeleteButtonContainer,
  DeleteButton,
  DeleteDatatable,
  DeleteThead,
  DeleteTh,
  DeleteTbody,
  DeleteTr,
  DeleteTd,
} from "../../styled/deleteComponent/DeleteButtonViews";
import { Modal, Button } from "react-bootstrap";
interface DeleteTypes {
  deleteDataGroup: () => void;
  deleteState: [Array<number>];
  tableData: Array<object | null>;
  tableType: string;
}

interface ModalTypes {
  show: boolean;
  handleClose: () => void;
  deleteDataGroup: () => void;
  deleteItems: Array<number>;
  tableData: Array<object | null>;
  tableType: string;
}

const DeleteModal = ({
  show,
  handleClose,
  deleteDataGroup,
  deleteItems,
  tableData,
  tableType,
}: ModalTypes) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <i className="bi bi-trash3"></i> Delete Clients{" "}
          </span>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure to delete these items.?</p>
        <DeleteDatatable>
          <DeleteThead>
            {tableType === "clients" && <DeleteTh>Client ID"</DeleteTh>}
            <DeleteTh>
              {tableType === "clients" ? "Name" : "Package Name"}
            </DeleteTh>
          </DeleteThead>
          <DeleteTbody>
            {deleteItems.map((dataId: any, key) => {
              const dataElement: any = tableData.find((data: any) => {
                const elementId =
                  tableType === "clients" ? data.id_clients : data.id_packages;
                return elementId === dataId;
              });

              return (
                <DeleteTr key={key}>
                  {tableType === "clients" && (
                    <DeleteTd>{dataElement.id_clients}</DeleteTd>
                  )}
                  <DeleteTd>{dataElement.name}</DeleteTd>
                </DeleteTr>
              );
            })}
          </DeleteTbody>
        </DeleteDatatable>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteDataGroup}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function DeleteComponent({
  deleteState,
  deleteDataGroup,
  tableData,
  tableType,
}: DeleteTypes) {
  const [show, setShow] = useState(false);
  const [deleteItems] = deleteState;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <DeleteButtonContainer>
        <DeleteButton onClick={handleShow}>
          <i className="bi bi-trash2" />
          <span className="deleteCount">{deleteItems.length}</span>
        </DeleteButton>
      </DeleteButtonContainer>

      <DeleteModal
        show={show}
        handleClose={handleClose}
        deleteDataGroup={deleteDataGroup}
        deleteItems={deleteItems}
        tableData={tableData}
        tableType={tableType}
      />
    </>
  );
}
