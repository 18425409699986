import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import { allPackagesDataRequest } from "../../../redux/slices/PackagesSlice";
import LoadingModal from "../modals/LoadingModal";
interface inputTypes {
  label: string | null;
  msg: string | null;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: any;
}

const PackageSelect = ({ onChange, label, msg, value }: inputTypes) => {
  const dispatch = useAppDispatch();
  const packages = useAppSelector((state) => state.packages);
  const { allPackagesData, isLoading } = packages;

  useEffect(() => {
    getPackagesData();
  }, []);

  const getPackagesData = () => {
    dispatch(allPackagesDataRequest());
  };

  return (
    <>
      <LoadingModal isLoading={isLoading} />
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{label}</Form.Label>
        <Form.Select onChange={onChange} value={value}>
          <option value={"no-value-1"}>{label}</option>
          {allPackagesData.map((packageSingle: any, key: number) => (
            <option key={key} value={packageSingle?.id_packages}>
              {packageSingle?.name}
            </option>
          ))}
        </Form.Select>
        <Form.Text className="text-danger">{msg}</Form.Text>
      </Form.Group>
    </>
  );
};

export default PackageSelect;
