import React, { useEffect, useRef } from "react";
import SubscriptionContainer from "./SubscriptionContainer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import {
  clearMessages,
  setCurrentReducer,
  subscriptionsDataRequest,
} from "../../redux/slices/SubscriptionsSlice";
export default function Subscription() {
  const firstUpdate = useRef(true);
  const dispatch = useAppDispatch();
  const subscriptions = useAppSelector((state) => state.subscriptions);
  const {
    subscriptionsData,
    isLoading,
    error,
    finalLoad,
    limit,
    apiStatus,
    successMessage,
    currentPosition,
    filterShadow,
  } = subscriptions;

  useEffect(() => {
    if (!firstUpdate.current && !filterShadow) {
      getSubscripionsData();
    }
  }, [currentPosition]);

  useEffect(() => {
    if (firstUpdate.current) {
      getSubscripionsData();
      firstUpdate.current = false;
    }
  }, []);

  const setCurrent = (param: number) => {
    dispatch(setCurrentReducer(param));
  };

  const getSubscripionsData = () => {
    const urlParams = `/${currentPosition}/${limit}`;
    dispatch(subscriptionsDataRequest(urlParams));
  };
  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  return (
    <div>
      <SubscriptionContainer
        subscriptionsData={subscriptionsData}
        isLoading={isLoading}
        error={error}
        finalLoad={finalLoad}
        currentState={[currentPosition, setCurrent]}
        limit={limit}
        apiStatus={apiStatus}
        successMessage={successMessage}
        clearMessages={clearAllMessages}
      />
    </div>
  );
}
