import moment from "moment";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useAppSelector } from "../../../../../redux/hooks/hooks";
import SendToWhatsapp from "../../../../../utils/Api/SendToWhatsapp";
import dateCompare from "../../../../../utils/date/dateCompare";
import TabContent from "./components/TabContent";

interface TabTypes {
  selectedItem: any;
}

export default function MessageTabs({ selectedItem }: TabTypes) {
  const gyms = useAppSelector((state) => state.gyms);
  const {
    subscribe_date: subscribedDate,
    phone,
    subscription_end_date: subscriptionEndDate,
    name,
    dob,
  } = selectedItem;
  const birthday = dateCompare.isBirthday(dob);
  const subscriptionEnd =
    dateCompare.isSubscriptionEndDate(subscriptionEndDate);
  const endDifference =
    dateCompare.subscriptionEndDifference(subscriptionEndDate);
  const formattedEndDate = moment(subscriptionEndDate).format("DD-MM-YYYY");
  const formattedSubscribedDate = moment(subscribedDate).format("DD-MM-YYYY");
  interface messageTypes {
    gymMessages: any;
    userDetails: any;
  }
  const { gymMessages, userDetails }: messageTypes = gyms;

  const formatMessages = (message: string) => {
    let formattedMessage = message.replace("_gym_name_", userDetails?.name);
    formattedMessage = formattedMessage.replace(
      "_subscription_end_",
      formattedEndDate
    );
    formattedMessage = formattedMessage.replace("_client_name_", name);
    formattedMessage = formattedMessage.replace(
      "_subscribed_date_",
      formattedSubscribedDate
    );
    return formattedMessage;
  };
  const birthdayMessage = formatMessages(gymMessages?.birthday_message);

  const subscriptionPending = formatMessages(gymMessages?.subscription_pending);

  const subscriptionEnded = formatMessages(gymMessages?.subscription_ended);

  const subscriptionReminder = formatMessages(
    gymMessages?.subscription_reminder
  );

  const defaultTab = () => {
    if (birthday) {
      return "birthday_message";
    } else if (endDifference > 1) {
      return "subscription_pending";
    } else if (subscriptionEnd) {
      return "subscription_ended";
    } else {
      return "subscription_reminder";
    }
  };

  const onMessageSend = (message: string) => {
    SendToWhatsapp(phone, message);
  };

  return (
    <Tabs
      defaultActiveKey={defaultTab()}
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      {endDifference > 1 ? (
        <Tab eventKey="subscription_pending" title="Pending">
          <TabContent
            value={subscriptionPending}
            onSubmit={(message) => onMessageSend(message)}
          />
        </Tab>
      ) : subscriptionEnd ? (
        <Tab eventKey="subscription_ended" title="Expired">
          <TabContent
            value={subscriptionEnded}
            onSubmit={(message) => onMessageSend(message)}
          />
        </Tab>
      ) : (
        <Tab eventKey="subscription_reminder" title="Reminder">
          <TabContent
            value={subscriptionReminder}
            onSubmit={(message) => onMessageSend(message)}
          />
        </Tab>
      )}

      {birthday && (
        <Tab eventKey="birthday_message" title="Birthday">
          <TabContent
            value={birthdayMessage}
            onSubmit={(message) => onMessageSend(message)}
          />
        </Tab>
      )}
    </Tabs>
  );
}
