import React, { useEffect, useRef, useState } from "react";
import ClientsContainer from "./ClientsContainer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import {
  clearMessages,
  clientsDataRequest,
  clientsDeleteRequest,
  setCurrentReducer,
  setFilterReducer,
  setDeleteItemsReducer,
  setFilterShadow,
  clearDeleteItems,
  setAddModalVisibleReducer,
  clientsAddRequest,
  clear,
  setClientsUpdateModalVisibleReducer,
  setSearchReducer,
} from "../../redux/slices/ClientsSlice";
const filterItems: Array<object> = [
  {
    label: "All",
    id: "name",
  },
  {
    label: "Inactive Only",
    id: "inactive_clients",
  },
  {
    label: "Active Only",
    id: "active_clients",
  },
];

const Clients = () => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state.clients);
  const [selectedItem, setSelectedItem] = useState({});
  const [newPackageModalShow, setNewPackageModalShow] = useState(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const {
    clientsData,
    isLoading,
    error,
    finalLoad,
    limit,
    apiStatus,
    successMessage,
    currentPosition,
    filter,
    deleteItems,
    filterShadow,
    addModalVisible,
    clientsUpdateModalVisible,
    search,
  } = clients;
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current && !filterShadow) {
      getClientsData();
    }
  }, [currentPosition]);

  useEffect(() => {
    if (!firstUpdate.current) {
      dispatch(setCurrentReducer(0));
      dispatch(clear());
      dispatch(setFilterShadow());
      dispatch(clearDeleteItems());
    }
  }, [filter, search]);

  useEffect(() => {
    filterShadow && getClientsData();
  }, [filterShadow]);

  useEffect(() => {
    if (firstUpdate.current) {
      getClientsData();
      firstUpdate.current = false;
    }
  }, []);

  const setCurrent = (param: number) => {
    dispatch(setCurrentReducer(param));
  };
  const setFilter = (param: string) => {
    dispatch(setFilterReducer(param));
  };
  const setDeleteItems = (param: Array<number>) => {
    dispatch(setDeleteItemsReducer(param));
  };
  const setAddModalVisible = (param: boolean) => {
    if (!param) {
      window.location.reload();
    }
    dispatch(setAddModalVisibleReducer(param));
  };

  const getClientsData = () => {
    const urlParams = `/${currentPosition}/${limit}/${filter}/'${search}'`;
    dispatch(clientsDataRequest(urlParams));
  };

  const selectAllHandle = (list: Array<number>) => {
    setDeleteItems(list);
  };

  const deleteClientsGroup = () => {
    if (deleteItems.length > 0) {
      const params: object = {
        ids: deleteItems.toString(),
      };
      dispatch(clientsDeleteRequest(params));
    }
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  const addClients = (params: object) => {
    dispatch(clientsAddRequest(params));
  };

  // Update section

  const clearUpdate = () => {
    dispatch(setClientsUpdateModalVisibleReducer(false));
    setNewPackageModalShow(false);
    setSelectedItem({});
    window.location.reload();
  };

  const onClickItem = (item: any) => {
    setSelectedItem(item);
    setNewPackageModalShow(false);
    dispatch(setClientsUpdateModalVisibleReducer(true));
  };

  const onUpdateModalClose = () => {
    dispatch(setClientsUpdateModalVisibleReducer(false));
  };

  const onNewPackageModalClose = () => {
    clearUpdate();
  };

  const onNewPackageModalShow = () => {
    onUpdateModalClose();
    setNewPackageModalShow(true);
  };

  // update section end

  // Message section end
  const onMessageModalShow = (item: any) => {
    setMessageModalShow(true);
    setSelectedItem(item);
  };

  const onMessageModalClose = () => {
    setMessageModalShow(false);
    setSelectedItem({});
  };

  // Message section end

  // search section

  const onSearch = (value: string) => {
    dispatch(setSearchReducer(value));
  };

  // search section end

  return (
    <ClientsContainer
      clientsData={clientsData}
      isLoading={isLoading}
      error={error}
      filterItems={filterItems}
      filterState={[filter, setFilter]}
      deleteState={[deleteItems]}
      finalLoad={finalLoad}
      currentState={[currentPosition, setCurrent]}
      limit={limit}
      apiStatus={apiStatus}
      deleteClientsGroup={deleteClientsGroup}
      successMessage={successMessage}
      clearMessages={clearAllMessages}
      selectAllHandle={selectAllHandle}
      addModalState={[addModalVisible, setAddModalVisible]}
      addClients={addClients}
      updateModalState={[
        selectedItem,
        onClickItem,
        onNewPackageModalShow,
        clientsUpdateModalVisible,
        onUpdateModalClose,
        clearUpdate,
      ]}
      newPackageModalState={[newPackageModalShow, onNewPackageModalClose]}
      messageModalState={[
        messageModalShow,
        onMessageModalClose,
        onMessageModalShow,
      ]}
      onSearch={onSearch}
    />
  );
};

export default Clients;
