import React, { useState } from "react";
import {
  ContainerArea,
  ContentArea,
} from "../../views/styled/layout/CommonViews";
import { TileContainer } from "../../views/styled/dashboard/DashBoardView";
import Tile from "./components/Tile";
import MessageEditModal from "../../views/elements/modals/dashboard/messageEditModal/MessageEditModal";
import LoadingModal from "../../views/elements/modals/LoadingModal";
import MessageToast from "../../views/elements/toast/MesageToast";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { setSideBarShowReducer } from "../../redux/slices/AppUtilSlice";

interface DashboardTypes {
  isLoading: boolean;
  error: string;
  clientsCount: number;
  apiStatus: boolean;
  successMessage: string;
  clearMessages: () => void;
  gymsUpdateModalState: [boolean, (param: boolean) => void];
  messagesUpdateModalState: [boolean, (param: boolean) => void];
}

export default function DashboardContainer({
  isLoading,
  error,
  clientsCount,
  apiStatus,
  successMessage,
  clearMessages,
  gymsUpdateModalState,
  messagesUpdateModalState,
}: DashboardTypes) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSHowSideBar = () => {
    dispatch(setSideBarShowReducer(true));
  };
  const [messageUpdateModalVisible, setMessagesUpdateModalVisible] =
    messagesUpdateModalState;
  return (
    <ContainerArea>
      <LoadingModal isLoading={isLoading} />
      <MessageEditModal
        show={messageUpdateModalVisible}
        onUpdateModalClose={() => setMessagesUpdateModalVisible(false)}
      />
      <ContentArea>
        <TileContainer>
          <Tile
            icon="bi bi-buildings"
            tileHeading="My Gym"
            onClick={onSHowSideBar}
          />

          <Tile
            icon="bi bi-people"
            tileHeading={`Clients: ${clientsCount}`}
            onClick={() => navigate("/clients")}
          />

          <Tile
            icon="bi bi-bag-dash"
            tileHeading={"Packages"}
            onClick={() => navigate("/packages")}
          />

          <Tile
            icon="bi bi-currency-rupee"
            tileHeading={"Subscriptions"}
            onClick={() => navigate("/subscriptions")}
          />

          <Tile
            icon="bi bi-gear-wide"
            tileHeading={"Settings"}
            onClick={() => console.log("")}
          />

          {/* <Tile
            icon="bi bi-chat-right-dots"
            tileHeading="Edit Messages"
            onClick={() => setMessagesUpdateModalVisible(true)}
          /> */}
        </TileContainer>

        <MessageToast
          error={error}
          successMessage={successMessage}
          clearMessages={clearMessages}
        />
      </ContentArea>
    </ContainerArea>
  );
}
