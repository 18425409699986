import React, { useEffect } from "react";
import DashboardContainer from "./DashboardContainer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import {
  clearMessages,
  clientsCountRequest,
  setGymsUpdateModalVisibleReducer,
  setMessagesUpdateModalVisibleReducer,
} from "../../redux/slices/GymSlice";
export default function Dashboard() {
  const gyms = useAppSelector((state) => state.gyms);
  const {
    isLoading,
    error,
    apiStatus,
    successMessage,
    clientsCount,
    gymsUpdateModalVisible,
    messageUpdateModalVisible,
  } = gyms;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clientsCountRequest(0));
  }, []);

  const setGymsUpdateModalVisible = (param: boolean) => {
    dispatch(setGymsUpdateModalVisibleReducer(param));
  };

  const setMessagesUpdateModalVisible = (param: boolean) => {
    dispatch(setMessagesUpdateModalVisibleReducer(param));
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  return (
    <div>
      <DashboardContainer
        isLoading={isLoading}
        error={error}
        clientsCount={clientsCount}
        apiStatus={apiStatus}
        successMessage={successMessage}
        clearMessages={clearAllMessages}
        gymsUpdateModalState={[
          gymsUpdateModalVisible,
          setGymsUpdateModalVisible,
        ]}
        messagesUpdateModalState={[
          messageUpdateModalVisible,
          setMessagesUpdateModalVisible,
        ]}
      />
    </div>
  );
}
