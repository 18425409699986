import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButtonContainer,
  BackButtonIcon,
} from "../../styled/filterbar/FilterViews";
const BackButton = () => {
  const navigation = useNavigate();
  return (
    <BackButtonContainer onClick={() => navigation(-1)}>
      <BackButtonIcon className="bi bi-arrow-left-short" />
    </BackButtonContainer>
  );
};

export default BackButton;
