import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiCaller } from "../../api/ApiCaller";
import endPoint from "../../api/EndPoints";
import HeaderGenerate from "../../utils/Api/HeaderGenerate";
import Ls from "../../utils/localStorage/Ls";
import { logout } from "./LoginSlice";

interface GymType {
  isLoading: boolean;
  error: string;
  apiStatus: boolean;
  successMessage: string;
  clientsCount: number;
  gymsUpdateModalVisible: boolean;
  messageUpdateModalVisible: false;
  userDetails: object;
  gymMessages: object;
}

const userJson: any = Ls.getItem("user");
const mesageJson: any = Ls.getItem("messages");

const initialState: GymType = {
  isLoading: false,
  error: "",
  apiStatus: false,
  successMessage: "",
  clientsCount: 0,
  gymsUpdateModalVisible: false,
  messageUpdateModalVisible: false,
  userDetails: userJson,
  gymMessages: mesageJson,
};

export const gymsUpdateRequest = createAsyncThunk(
  "GYMS_UPDATE_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const hasImage = params?.image_gyms;
      const headers = HeaderGenerate(hasImage);
      const destination = params?.image_gyms
        ? endPoint.gymsWithImage
        : endPoint.gyms;
      const gymsUpdateResponse: any = await apiCaller(
        destination,
        "PUT",
        params,
        headers
      );
      return gymsUpdateResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const messagesUpdateRequest = createAsyncThunk(
  "MESSAGES_UPDATE_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const headers = HeaderGenerate();
      const destination = endPoint.messages;
      const messageUpdateResponse: any = await apiCaller(
        destination,
        "PUT",
        params,
        headers
      );
      return messageUpdateResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const clientsCountRequest = createAsyncThunk(
  "CLIENTS_COUNT_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const headers = HeaderGenerate();
      const destination = endPoint.clients;
      const messageUpdateResponse: any = await apiCaller(
        destination,
        "GET",
        {},
        headers
      );
      return messageUpdateResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const gymsSlice = createSlice({
  name: "Gyms",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.error = "";
      state.successMessage = "";
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setApiStatus: (state) => {
      state.apiStatus = true;
    },
    setGymsUpdateModalVisibleReducer: (state, action) => {
      state.gymsUpdateModalVisible = action.payload;
    },
    setMessagesUpdateModalVisibleReducer: (state, action) => {
      state.messageUpdateModalVisible = action.payload;
    },
    setGymDetailsReducer: (state, action) => {
      state.userDetails = action.payload.userDetails;
      state.gymMessages = action.payload.gymMessages;
      Ls.setItem("user", JSON.stringify(action.payload.userDetails));
      Ls.setItem("messages", JSON.stringify(action.payload.gymMessages));
    },
  },
  extraReducers: {
    [gymsUpdateRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [gymsUpdateRequest.fulfilled.type]: (state, action) => {
      state.apiStatus = false;
      state.error = "";
      state.userDetails = action.payload.data;
      state.gymsUpdateModalVisible = false;
      Ls.setItem("user", JSON.stringify(action.payload.data));
      state.isLoading = false;
      state.successMessage = "Gym Details Changed Successfully.!";
    },
    [gymsUpdateRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },

    [messagesUpdateRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [messagesUpdateRequest.fulfilled.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.gymMessages = action.payload.data;
      state.messageUpdateModalVisible = false;
      Ls.setItem("messages", JSON.stringify(action.payload.data));
      state.successMessage = "Gym Mesages Changed Successfully.!";
    },
    [messagesUpdateRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },

    [clientsCountRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [clientsCountRequest.fulfilled.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.clientsCount = parseInt(action.payload.data.totalClients);
    },
    [clientsCountRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },
  },
});

export const {
  setLoading,
  setApiStatus,
  clearMessages,
  setGymsUpdateModalVisibleReducer,
  setMessagesUpdateModalVisibleReducer,
  setGymDetailsReducer,
} = gymsSlice.actions;

export const gymsState = (state: RootState) => state.gyms;

export default gymsSlice.reducer;
