import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const TabTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 5px;
  margin-bottom: 10px;
`;

export const MessageButtonContainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: row;
`;

export const MessageButtonView = styled.button`
  box-shadow: 0 0 10px #ddd;
  width: 20px;
  min-height: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${appColors.white};
  border: none;
  margin: 0px 10px;
  i {
    font-size: 15px;
  }
`;

export const FloatingMessageButtonContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 20px;
  @media screen and (min-width: 768px) {
    right: 40px;
  }
`;

export const FloatingMessageButton = styled.button`
  background: ${appColors.whatsappGreen};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 15px;
  i {
    font-size: 30px;
    text-align: 30px;
    color: ${appColors.white};
  }
`;
