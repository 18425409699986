import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
`;

export const ImageArea = styled.div`
  border-radius: 50px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${appColors.liteGrey};
  border: 2px solid ${appColors.white};
  i {
    font-size: 50px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

export const InputBox = styled.input`
  display: none;
`;

export const ErrorText = styled.p`
  color: ${appColors.lightRed};
  font-size: 13px;
  padding: 10px 0px;
`;

export const ClickEditLabel = styled.p`
  font-size: 13px;
  font-weight: 700;
  color: ${appColors.black};
  margin: 0;
  padding: 0;
`;

export const ClickEditInput = styled.input`
  font-size: 16px;
  border: none;
  margin: 0;

  &:focus {
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const ClickEditArea = styled.textarea`
  font-size: 16px;
  width: 100%;
  border: none;
  margin: 0;

  &:focus {
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const ClickEditValue = styled.p`
  font-size: 16px;
  color: ${appColors.black};
  margin: 0;
  padding: 0;
`;

export const ClickEditError = styled.p`
  font-size: 12px;
  color: ${appColors.lightRed};
  margin: 0;
  padding: 0;
`;
export const ClickEditValueContainer = styled.div`
  display: flex;
  flexdirection: row;
  align-items: center;
  position: relative;
  padding: 5px 0px 5px 5px;
  overflow: auto;
  word-wrap: break-word;
  i {
    font-size: 10px;
    position: absolute;
    right: 5px;
  }
`;

export const ClickEditContent = styled.div`
  padding: 10px 5px;
`;

export const ClickEditColumn = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: center;
`;

export const ClickEditContainer = styled.div`
  padding: 10px;
  margin: 0;
  width: 300px;
  box-shadow: 0 0 10px #ddd;

  @media screen and (max-width: 1024px) {
    width: 220px;
  }

  @media screen and (max-width: 590px) {
    width: 190px;
  }

  @media screen and (max-width: 450px) {
    width: 150px;
  }

  @media screen and (max-width: 290px) {
    width: 100px;
  }
`;

export const ClickSelectBox = styled.select`
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;

  &:focus {
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const ClickEditDate = styled.input`
  padding: 0;
  width: 100%;
  border: none;
  font-size: 16px;
  border: none;
  margin: 0;

  // &:focus{
  //   border: none;
  // }

  // &:focus-visible{
  //   border: none;
  //   outline:none;
  // }
`;

export const ClickEditSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`;

export const SubmitButtonContainer = styled.div`
  padding: 0px 10px;
`;

export const SubmitButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  color: ${appColors.black};
  font-size: 13px;
  font-weight: 700;
  background: ${appColors.white};
`;

export const ButtonGreen = styled.button`
  padding: 10px;
  border-radius: 10px;
  color: ${appColors.black};
  font-size: 13px;
  font-weight: 700;
  background: ${appColors.liteGreen};
  i {
    padding: 0px 5px 0px 0px;
  }
`;

export const ButtonRed = styled.button`
  padding: 10px;
  border-radius: 10px;
  color: ${appColors.black};
  font-size: 13px;
  font-weight: 700;
  background: ${appColors.white};
`;

export const PackageSelectButton = styled.div`
  padding: 0px 10px;
`;

export const PackageSelectOptionsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
`;

export const PackageSelectOption = styled.div`
  padding: 0px 10px;
`;

export const CustomCheckBox = styled.input`
  background: ${appColors.grey};
`;

export const SelectAllCheckBoxContainer = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
`;
