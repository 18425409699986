import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiCaller } from "../../api/ApiCaller";
import endPoint from "../../api/EndPoints";
import HeaderGenerate from "../../utils/Api/HeaderGenerate";
import Ls from "../../utils/localStorage/Ls";
import { setGymDetailsReducer } from "./GymSlice";

interface LoginState {
  isLoggedIn: boolean;
  isLoading?: boolean;
  key: string;
  error?: string;
  successMessage: string;
}

const loginJson: any = Ls.getItem("login");
const initialState: LoginState = {
  isLoggedIn: loginJson?.isLoggedIn,
  isLoading: false,
  key: loginJson?.key,
  successMessage: "",
  error: "",
};

interface LoginTypes {
  params: object;
  navigate: any;
}
export const loginRequest = createAsyncThunk(
  "LOGIN_REQUEST",
  async ({ params, navigate }: LoginTypes, thunkAPI: any) => {
    try {
      const headers = HeaderGenerate();
      const loginResponse: any = await apiCaller(
        endPoint.login,
        "POST",
        params,
        headers
      );

      const gymData = {
        userDetails: loginResponse.data.data,
        gymMessages: loginResponse.data.gymMessages,
      };
      thunkAPI.dispatch(setGymDetailsReducer(gymData));
      navigate("/");
      return loginResponse.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

interface SignUpTypes {
  params: any;
  navigate: any;
}

export const signUpRequest = createAsyncThunk(
  "SIGNUP_REQUEST",
  async ({ params, navigate }: SignUpTypes, thunkAPI: any) => {
    try {
      const hasImage = params?.image_gyms;
      const headers = HeaderGenerate(hasImage);
      const destination = params?.image_gyms
        ? endPoint.signUpWithImage
        : endPoint.signUp;
      const loginResponse: any = await apiCaller(
        destination,
        "POST",
        params,
        headers
      );

      const gymData = {
        userDetails: loginResponse.data.data,
        gymMessages: loginResponse.data.gymMessages,
      };
      thunkAPI.dispatch(setGymDetailsReducer(gymData));
      navigate("/");
      return loginResponse.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.key = "";
      state.error = "";
      Ls.removeItem("login");
      Ls.removeItem("user");
      Ls.removeItem("messages");
    },
    clearMessages: (state) => {
      state.error = "";
      state.successMessage = "";
    },
  },
  extraReducers: {
    [loginRequest.pending.type]: (state) => {
      state.isLoggedIn = false;
      state.isLoading = true;
      state.key = "";
      state.error = "";
    },
    [loginRequest.fulfilled.type]: (state, action) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.key = action.payload?.token;
      state.error = "";

      const loginObj: any = {
        isLoggedIn: true,
        key: action.payload?.token,
      };
      Ls.setItem("login", JSON.stringify(loginObj));
    },
    [loginRequest.rejected.type]: (state, action) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.key = "";
      state.error = action.payload?.data?.error;
    },

    [signUpRequest.pending.type]: (state) => {
      state.isLoggedIn = false;
      state.isLoading = true;
      state.key = "";
      state.error = "";
    },
    [signUpRequest.fulfilled.type]: (state, action) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.key = action.payload?.token;
      state.error = "";

      const loginObj: any = {
        isLoggedIn: true,
        key: action.payload?.token,
      };
      Ls.setItem("login", JSON.stringify(loginObj));
    },
    [signUpRequest.rejected.type]: (state, action) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.key = "";
      state.error = action.payload?.data?.error;
    },
  },
});

export const { logout, clearMessages } = loginSlice.actions;

export const loginState = (state: RootState) => state.login;

export default loginSlice.reducer;
