import React, { useRef } from "react";
import {
  ErrorText,
  ImageArea,
  InputBox,
} from "../../../styled/forms/FormComponents";

interface ImageTypes {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedImage: string | null;
  error: string;
}

const CustomInputFile = ({ onChange, selectedImage, error }: ImageTypes) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const onClickImage: React.MouseEventHandler<HTMLDivElement> = (e) => {
    imageRef?.current?.click();
  };

  return (
    <>
      {selectedImage && selectedImage != "" ? (
        <ImageArea onClick={onClickImage}>
          <img src={selectedImage} />
        </ImageArea>
      ) : (
        <ImageArea onClick={onClickImage}>
          <i className="bi bi-person-add" />
        </ImageArea>
      )}
      {error && <ErrorText>{error}</ErrorText>}
      <InputBox type="file" ref={imageRef} onChange={onChange} />
    </>
  );
};

export default CustomInputFile;
