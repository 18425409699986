import React from "react";
import EndPoints from "../../../../../api/EndPoints";
import { FileDownloader } from "../../../../../api/FileDownloader";
import HeaderGenerate from "../../../../../utils/Api/HeaderGenerate";
import {
  MessageButtonContainer,
  MessageButtonView,
} from "../../../../../views/styled/message/MessagesViews";
import {
  DetailText,
  DownloadButtonContainer,
  DownloadButtonText,
  DownloadButtonView,
} from "../../../../../views/styled/dataTable/DataTableViews";

interface DownloadTypes {
  paymentId: number;
  setLoading: (param: boolean) => void;
}
export default function DownloadButton({
  paymentId,
  setLoading,
}: DownloadTypes) {
  const onGeneratePdf = async () => {
    setLoading(true);
    const headers = HeaderGenerate();
    try {
      const subscriptionsDataResponse: any = await FileDownloader(
        EndPoints.paymentsInvoice + paymentId,
        "GET",
        {},
        headers
      );
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };
  return (
    <DownloadButtonContainer>
      <DownloadButtonView onClick={onGeneratePdf}>
        <DownloadButtonText> Download PDF</DownloadButtonText>
        <i className={"bi bi-filetype-pdf"} />
      </DownloadButtonView>
    </DownloadButtonContainer>
  );
}
