import React, { useState, useEffect } from "react";
import validator from "../../../utils/validator/validator";
import moment from "moment";
import {
  ClickEditColumn,
  ClickEditContent,
  ClickEditSubmitContainer,
  SubmitButton,
} from "../../../views/styled/forms/FormComponents";
import { useAppDispatch } from "../../../redux/hooks/hooks";
import { packagesUpdateRequest } from "../../../redux/slices/PackagesSlice";
import ClickEdit from "../../../views/components/form/ClickEdit";
interface UpdateClientTypes {
  dataItem: any;
}

const UpdatePackage = ({ dataItem }: UpdateClientTypes) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState(true);
  const [edited, setEdited] = useState(false);

  const [name, setName] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const [validity, setValidity] = useState({
    value: 0,
    msg: "",
    valid: false,
  });

  const [amount, setAmount] = useState({
    value: 0,
    msg: "",
    valid: false,
  });

  const [description, setDescription] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setName((prevName) => {
      return { value: dataItem.name, msg: "", valid: true };
    });

    setValidity((prevValidity) => {
      return { value: dataItem.validity, msg: "", valid: true };
    });

    setAmount((prevAmount) => {
      return { value: dataItem.amount, msg: "", valid: true };
    });

    setDescription((prevDescription) => {
      return { value: dataItem.description, msg: "", valid: true };
    });
  };

  useEffect(() => {
    if (name.valid && validity.valid && amount.valid && description.valid) {
      setError(false);
    } else {
      setError(true);
    }
  }, [name, validity, amount, description]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName((prevName) => {
      return { value, msg: prevName.msg, valid: prevName.valid };
    });
    if (validator.specialCharChecker(value)) {
      setName((prevName) => {
        return { value: prevName.value, msg: "Invalid Name", valid: false };
      });
    } else {
      setEdited(true);
      setName((prevName) => {
        return { value: prevName.value, msg: "", valid: true };
      });
    }
  };

  const onValidityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setValidity((prevvalidity) => {
      return { value, msg: prevvalidity.msg, valid: prevvalidity.valid };
    });
    if (validator.isValidity(e.target.value)) {
      setEdited(true);
      setValidity((prevvalidity) => {
        return { value: prevvalidity.value, msg: "", valid: true };
      });
    } else {
      setValidity((prevvalidity) => {
        return {
          value: prevvalidity.value,
          msg: "Invalid validity.!",
          valid: false,
        };
      });
    }
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setAmount((prevAmount) => {
      return { value, msg: prevAmount.msg, valid: prevAmount.valid };
    });
    if (validator.isAmount(e.target.value)) {
      setEdited(true);
      setAmount((prevAmount) => {
        return { value: prevAmount.value, msg: "", valid: true };
      });
    } else {
      setAmount((prevAmount) => {
        return {
          value: prevAmount.value,
          msg: "Invalid Amount.!",
          valid: false,
        };
      });
    }
  };
  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDescription((prevDescription) => {
      return { value, msg: prevDescription.msg, valid: prevDescription.valid };
    });
    if (!validator.specialCharChecker(value)) {
      setEdited(true);
      setDescription((prevDescription) => {
        return { value: prevDescription.value, msg: "", valid: true };
      });
    } else {
      setDescription((prevDescription) => {
        return {
          value: prevDescription.value,
          msg: "Invalid Description.!",
          valid: false,
        };
      });
    }
  };

  const clearForm = () => {
    setName({
      value: "",
      msg: "",
      valid: false,
    });
    setDescription({
      value: "",
      msg: "",
      valid: false,
    });

    setValidity({
      value: 0,
      msg: "",
      valid: false,
    });
    setAmount({
      value: 0,
      msg: "",
      valid: false,
    });
  };

  const onSubmit = () => {
    if (!error) {
      const formattedPackageId = dataItem?.id_packages.toString();
      const packagesData: object = {
        id_packages: formattedPackageId,
        name: name.value,
        validity: validity.value.toString(),
        amount: amount.value.toString(),
        description: description.value,
        status: "true",
      };

      dispatch(packagesUpdateRequest(packagesData));
    } else {
      alert("wrong input");
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <ClickEditColumn>
        <ClickEditContent>
          <ClickEdit
            label="Package Name"
            value={name.value}
            onChange={onNameChange}
            type="text"
            msg={name.msg}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickEdit
            label="Validiy (In days)"
            value={validity.value}
            onChange={onValidityChange}
            type="number"
            msg={validity.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      <ClickEditColumn>
        <ClickEditContent>
          <ClickEdit
            label="Amount (In rupees)"
            value={amount.value}
            onChange={onAmountChange}
            type="number"
            msg={amount.msg}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickEdit
            label="Description"
            value={description.value}
            onChange={onDescriptionChange}
            type="text"
            msg={description.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      {edited && !error && (
        <ClickEditSubmitContainer>
          <SubmitButton onClick={onSubmit}>Update Details</SubmitButton>
        </ClickEditSubmitContainer>
      )}
    </form>
  );
};

export default UpdatePackage;
