import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { ErrorToastContainer } from "../../styled/layout/CommonViews";

interface ErrorTypes {
  error: string;
  successMessage: string;
  clearMessages: () => void;
}

const MessageToast = ({ error, successMessage, clearMessages }: ErrorTypes) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (error?.length > 0) {
      setSuccess(false);
      setShow(true);
    } else if (successMessage?.length > 0) {
      setSuccess(true);
      setShow(true);
    }
  }, [error, successMessage]);
  return (
    <ErrorToastContainer>
      <Toast
        show={show}
        onClose={() => {
          setShow(false);
          clearMessages();
        }}
      >
        <Toast.Header>
          {success ? (
            <i className="bi bi-check-circle" />
          ) : (
            <i className="bi bi-slash-circle" />
          )}

          <strong className="me-auto">{success ? "Done." : "Error.!"}</strong>
          <small></small>
        </Toast.Header>
        <Toast.Body>{success ? successMessage : error}</Toast.Body>
      </Toast>
    </ErrorToastContainer>
  );
};

export default MessageToast;
