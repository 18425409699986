import React from "react";
import { CustomCheckBox } from "../../../../../views/styled/forms/FormComponents";

interface SelectorTypes {
  checked: boolean;
  dataId: number;
  deleteListCreate: (dataId: number) => void;
}

const SelectorCheckbox = ({
  dataId,
  deleteListCreate,
  checked,
}: SelectorTypes) => {
  const changeCheck = () => {
    deleteListCreate(dataId);
  };
  return (
    <CustomCheckBox type="checkbox" checked={checked} onChange={changeCheck} />
  );
};

export default SelectorCheckbox;
