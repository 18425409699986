import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import { allPackagesDataRequest } from "../../../redux/slices/PackagesSlice";
import {
  ClickEditContainer,
  ClickEditError,
  ClickEditLabel,
  ClickEditValueContainer,
  ClickSelectBox,
} from "../../styled/forms/FormComponents";
import LoadingModal from "../../elements/modals/LoadingModal";
interface inputTypes {
  label: string | null;
  msg: string | null;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: any;
}

const ClickPackageSelect = ({ onChange, label, msg, value }: inputTypes) => {
  const dispatch = useAppDispatch();
  const packages = useAppSelector((state) => state.packages);

  const { allPackagesData, isLoading } = packages;

  useEffect(() => {
    getPackagesData();
  }, []);

  const getPackagesData = () => {
    dispatch(allPackagesDataRequest());
  };

  return (
    <>
      <LoadingModal isLoading={isLoading} />
      <ClickEditContainer>
        <ClickEditLabel>{label}</ClickEditLabel>
        <ClickEditValueContainer>
          <ClickSelectBox onChange={onChange} value={value}>
            <option value={"no-value-1"}>{label}</option>
            {allPackagesData.map((packageSingle: any, key: number) => (
              <option key={key} value={packageSingle?.id_packages}>
                {packageSingle?.name}
              </option>
            ))}
          </ClickSelectBox>
        </ClickEditValueContainer>
        <ClickEditError>{msg}</ClickEditError>
      </ClickEditContainer>
    </>
  );
};

export default ClickPackageSelect;
