import React from "react";
import AppBar from "./components/AppBar";
import { Outlet } from "react-router-dom";
import OffCanvasView from "../../views/elements/offcanvas/OffcanvasView";
import SideBar from "./components/SideBar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { setSideBarShowReducer } from "../../redux/slices/AppUtilSlice";
const Header = () => {
  const dispatch = useAppDispatch();
  const appUtils = useAppSelector((state) => state.appUtils);
  const { sideBarShow } = appUtils;
  const onHide = () => {
    dispatch(setSideBarShowReducer(false));
  };
  return (
    <>
      <AppBar />
      <OffCanvasView
        show={sideBarShow}
        onHide={onHide}
        children={<SideBar onHide={onHide} />}
      />
      <Outlet />
    </>
  );
};

export default Header;
