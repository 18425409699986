import React from "react";
import {
  ContainerArea,
  ContentArea,
  EmptyArea,
  SubHeadingText,
} from "../../views/styled/layout/CommonViews";
import LoadingModal from "../../views/elements/modals/LoadingModal";
import MessageToast from "../../views/elements/toast/MesageToast";
import DataListTable from "../components/table/DataListTable/DataListTable";
import {
  FilterMenuBar,
  HeadingSection,
  PageHeading,
} from "../../views/styled/filterbar/FilterViews";
import BackButton from "../../views/components/goback/BackButton";

interface SubscripionsTypes {
  subscriptionsData: Array<object | null>;
  isLoading: boolean;
  error: string;
  finalLoad: boolean;
  currentState: [number, (param: number) => void];
  limit: number;
  apiStatus: boolean;
  successMessage: string;
  clearMessages: () => void;
}

export default function SubscriptionContainer({
  subscriptionsData,
  isLoading,
  error,
  finalLoad,
  currentState,
  limit,
  apiStatus,
  successMessage,
  clearMessages,
}: SubscripionsTypes) {
  return (
    <ContainerArea>
      <FilterMenuBar>
        <BackButton />
        <HeadingSection>
          <PageHeading>Subscriptions</PageHeading>
        </HeadingSection>
      </FilterMenuBar>

      {!subscriptionsData.length && (
        <EmptyArea>
          <SubHeadingText>
            No subscribed packages to show. Please get any subscription.
          </SubHeadingText>
        </EmptyArea>
      )}

      <LoadingModal isLoading={isLoading} />

      <ContentArea>
        {subscriptionsData.length > 0 && (
          <DataListTable
            tableData={subscriptionsData}
            currentState={currentState}
            finalLoad={finalLoad}
            isLoading={isLoading}
            limit={limit}
            tableType={"subscriptions"}
            apiStatus={apiStatus}
          />
        )}

        <MessageToast
          error={error}
          successMessage={successMessage}
          clearMessages={clearMessages}
        />
      </ContentArea>
    </ContainerArea>
  );
}
