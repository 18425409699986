import React from "react";
import {
  OffsetCloseButton,
  OffsetCloseIcon,
  SideBarBannerContainer,
  SideBarContainer,
} from "../../../views/styled/sidebar/SideBarViews";
import MyGymView from "./MyGymView";
interface SideBarTypes {
  onHide: () => void;
}
const SideBar = ({ onHide }: SideBarTypes) => {
  return (
    <SideBarContainer>
      <OffsetCloseButton onClick={onHide}>
        <OffsetCloseIcon className={"bi bi-x-circle"} />
      </OffsetCloseButton>
      <SideBarBannerContainer />
      <MyGymView />
    </SideBarContainer>
  );
};

export default SideBar;
