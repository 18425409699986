import React, { useEffect, useState } from "react";
import SignupContainer from "./SignupContainer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import validator from "../../utils/validator/validator";
import { clearMessages, signUpRequest } from "../../redux/slices/LoginSlice";
import GetLocation from "../../utils/geolocation/GetLocation";
import { useNavigate } from "react-router-dom";
export default function SignUp() {
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState(true);
  const navigate = useNavigate();
  const login = useAppSelector((state: any) => state?.login);
  const { error } = login;

  interface SeletedImageType {
    value: File | null;
    msg: string;
    valid: boolean;
  }
  const [selectedImage, setSelectedImage] = useState<SeletedImageType>({
    value: null,
    msg: "",
    valid: false,
  });

  const [imagethumb, setImageThumb] = useState("");

  const [name, setName] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [location, setLocation] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [address, setAddress] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const [password, setPassword] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [altPhone, setAltPhone] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [subscription, setSubscription] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    locationAccess();
  }, []);

  const locationAccess = async () => {
    const getLocation: any = await GetLocation();
    onLocationChange(getLocation);
  };

  useEffect(() => {
    if (
      name.valid &&
      location.valid &&
      address.valid &&
      phone.valid &&
      altPhone.valid &&
      email.valid
    ) {
      setFormError(false);
    } else {
      setFormError(true);
    }
  }, [name, address, location, phone, altPhone, email, subscription]);

  const onNameChange = (e: any) => {
    const value = e.target.value;
    setName((prevName) => {
      return { value, msg: prevName.msg, valid: prevName.valid };
    });
    if (!validator.isName(e.target.value)) {
      setName((prevName) => {
        return { value: prevName.value, msg: "Invalid Name", valid: false };
      });
    } else {
      setName((prevName) => {
        return { value: prevName.value, msg: "", valid: true };
      });
    }
  };

  const onLocationChange = (location: any) => {
    if (location?.locationAccess) {
      setLocation((prevLocation) => {
        return { value: location?.location, msg: "", valid: true };
      });
    } else {
      setLocation((prevLocation) => {
        return { value: prevLocation.value, msg: location?.msg, valid: false };
      });
    }
  };

  const onAddressChange = (e: any) => {
    const value = e.target.value;
    setAddress((prevAddress) => {
      return { value, msg: prevAddress.msg, valid: prevAddress.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setAddress((prevAddress) => {
        return { value: prevAddress.value, msg: "", valid: true };
      });
    } else {
      setAddress((prevAddress) => {
        return {
          value: prevAddress.value,
          msg: "Invalid Address",
          valid: false,
        };
      });
    }
  };

  const onPhoneChange = (e: any) => {
    const value = e.target.value;
    setPhone((prevPhone) => {
      return { value, msg: prevPhone.msg, valid: prevPhone.valid };
    });
    if (validator.isMobileNum(e.target.value)) {
      setPhone((prevPhone) => {
        return { value: prevPhone.value, msg: "", valid: true };
      });
    } else {
      setPhone((prevPhone) => {
        return {
          value: prevPhone.value,
          msg: "Invalid Phone Number",
          valid: false,
        };
      });
    }
  };

  const onAltPhoneChange = (e: any) => {
    const value = e.target.value;
    setAltPhone((prevAltPhone) => {
      return { value, msg: prevAltPhone.msg, valid: prevAltPhone.valid };
    });
    if (validator.isMobileNum(e.target.value)) {
      setAltPhone((prevAltPhone) => {
        return { value: prevAltPhone.value, msg: "", valid: true };
      });
    } else {
      setAltPhone((prevAltPhone) => {
        return {
          value: prevAltPhone.value,
          msg: "Invalid Alternate Phone Number",
          valid: false,
        };
      });
    }
  };

  const onEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail((prevEmail) => {
      return { value, msg: prevEmail.msg, valid: prevEmail.valid };
    });
    if (validator.isEmail(e.target.value)) {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "", valid: true };
      });
    } else {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "Invalid Email", valid: false };
      });
    }
  };

  const onPasswordChange = (e: any) => {
    const value = e.target.value;
    setPassword((prevPassword) => {
      return { value, msg: prevPassword.msg, valid: prevPassword.valid };
    });
    if (validator.isStrongPassword(e.target.value)) {
      setPassword((prevPassword) => {
        return { value: prevPassword.value, msg: "", valid: true };
      });
    } else {
      setPassword((prevPassword) => {
        return {
          value: prevPassword.value,
          msg: "Invalid Password",
          valid: false,
        };
      });
    }
  };

  const onSubscriptionChange = (e: any) => {
    const value = e.target.value;
    setSubscription((prevSubscription) => {
      return {
        value,
        msg: prevSubscription.msg,
        valid: prevSubscription.valid,
      };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setSubscription((prevSubscription) => {
        return { value: prevSubscription.value, msg: "", valid: true };
      });
    } else {
      setSubscription((prevSubscription) => {
        return {
          value: prevSubscription.value,
          msg: "Invalid Subscription",
          valid: false,
        };
      });
    }
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = e.target.files;
    const selectedFile: File | null = selectedFiles ? selectedFiles[0] : null;
    if (selectedFile && validator.isImage(selectedFile?.type)) {
      setImageThumb(URL.createObjectURL(selectedFile));
      setSelectedImage((prevImage) => {
        return { value: selectedFile, msg: "", valid: true };
      });
    } else {
      setImageThumb("");
      setSelectedImage((prevImage) => {
        return {
          value: null,
          msg: "Wrong image format.!(please upload .jpeg or .png)",
          valid: false,
        };
      });
    }
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  const onSubmit = () => {
    if (!formError) {
      const gymsData: object = {
        image_gyms: selectedImage.value,
        name: name.value,
        location: location.value,
        address: address.value,
        phone: phone.value,
        alt_phone: altPhone.value,
        email: email.value,
        subscription: subscription.value,
        password: password.value.toString(),
        status: "true",
      };

      dispatch(signUpRequest({ params: gymsData, navigate }));
    } else {
      alert("wrong input");
    }
  };
  return (
    <div>
      <SignupContainer
        emailState={[email, onEmailChange]}
        nameState={[name, onNameChange]}
        passwordState={[password, onPasswordChange]}
        locationState={[location, onLocationChange]}
        addressState={[address, onAddressChange]}
        phoneState={[phone, onPhoneChange]}
        altPhoneState={[altPhone, onAltPhoneChange]}
        subscriptionState={[subscription, onSubscriptionChange]}
        formError={formError}
        error={error}
        onSubmit={onSubmit}
        clearMessages={clearAllMessages}
        fileState={[imagethumb, onSelectFile]}
        selectedImage={selectedImage}
      />
    </div>
  );
}
