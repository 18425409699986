import React, { useState, useEffect } from "react";
import validator from "../../../utils/validator/validator";
import moment from "moment";
import {
  ButtonGreen,
  ClickEditColumn,
  ClickEditContent,
  ClickEditSubmitContainer,
  SubmitButton,
} from "../../../views/styled/forms/FormComponents";
import CustomInputFile from "../../../views/components/form/fileUploader/CustomInputFile";
import EndPoints from "../../../api/EndPoints";
import ClickDate from "../../../views/components/form/ClickDate";
import { useAppDispatch } from "../../../redux/hooks/hooks";
import { clientsUpdateRequest } from "../../../redux/slices/ClientsSlice";
import ClickPackageSelect from "../../../views/components/form/ClickPackageSelect";
import ClickEdit from "../../../views/components/form/ClickEdit";
import ClickSelect from "../../../views/components/form/ClickSelect";
import { PackageUpdateButtonContainer } from "../../../views/styled/clients/ClientsViews";
interface UpdateClientTypes {
  dataItem: any;
  onNewPackageModalShow: (param: any) => void;
}

const UpdateClient = ({
  dataItem,
  onNewPackageModalShow,
}: UpdateClientTypes) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState(true);
  const [edited, setEdited] = useState(false);
  const genderOptions: Array<object> = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];

  interface SeletedImageType {
    value: File | null;
    msg: string;
    valid: boolean;
  }
  const [selectedImage, setSelectedImage] = useState<SeletedImageType>({
    value: null,
    msg: "",
    valid: false,
  });

  const [imagethumb, setImageThumb] = useState("");

  const [packageID, setPackageId] = useState({
    value: 0,
    msg: "",
    valid: false,
  });

  const [name, setName] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [address, setAddress] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [gender, setGender] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [dob, setDob] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [weight, setWeight] = useState({
    value: 0,
    msg: "",
    valid: false,
  });
  const [height, setHeight] = useState({
    value: 0,
    msg: "",
    valid: false,
  });
  const [disease, setDisease] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [subscriptionDate, setSubscriptionDate] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [disability, setDisability] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setImageThumb(
      dataItem.image_clients
        ? EndPoints.clientImageUrl + dataItem.image_clients
        : ""
    );

    setPackageId((prevPackageId) => {
      return { value: dataItem.id_packages, msg: "", valid: true };
    });

    setName((prevPackageId) => {
      return { value: dataItem.name, msg: "", valid: true };
    });

    setAddress((prevPackageId) => {
      return { value: dataItem.address, msg: "", valid: true };
    });

    setPhone((prevPackageId) => {
      return { value: dataItem.phone, msg: "", valid: true };
    });

    setGender((prevPackageId) => {
      return { value: dataItem.gender, msg: "", valid: true };
    });

    const formatDob = moment(dataItem.dob).format("YYYY-MM-DD");

    setDob((prevPackageId) => {
      return { value: formatDob, msg: "", valid: true };
    });

    setEmail((prevPackageId) => {
      return { value: dataItem.email, msg: "", valid: true };
    });

    setHeight((prevPackageId) => {
      return { value: dataItem.height, msg: "", valid: true };
    });

    setWeight((prevPackageId) => {
      return { value: dataItem.weight, msg: "", valid: true };
    });

    setDisease((prevPackageId) => {
      return { value: dataItem.diseases, msg: "", valid: true };
    });

    setDisability((prevPackageId) => {
      return { value: dataItem.disability, msg: "", valid: true };
    });

    const formatSubsDate = moment(dataItem.subscription_date).format(
      "YYYY-MM-DD"
    );

    setSubscriptionDate((prevPackageId) => {
      return { value: formatSubsDate, msg: "", valid: true };
    });
  };

  useEffect(() => {
    if (
      packageID.valid &&
      name.valid &&
      address.valid &&
      phone.valid &&
      gender.valid &&
      dob.valid &&
      email.valid &&
      height.valid &&
      weight.valid &&
      disability.valid &&
      disease.valid &&
      subscriptionDate.valid
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [
    packageID,
    name,
    address,
    phone,
    gender,
    dob,
    email,
    height,
    weight,
    disability,
    disease,
    subscriptionDate,
  ]);

  const onPackageIdChange = (e: any) => {
    const value: number = parseInt(e.target.value);
    setPackageId((prevPackageId) => {
      return { value, msg: prevPackageId.msg, valid: prevPackageId.valid };
    });
    if (!validator.isId(e.target.value)) {
      setPackageId((prevPackageId) => {
        return {
          value: prevPackageId.value,
          msg: "Invalid Package Id.",
          valid: false,
        };
      });
    } else {
      setEdited(true);
      setPackageId((prevPackageId) => {
        return { value: prevPackageId.value, msg: "", valid: true };
      });
    }
  };

  const onNameChange = (e: any) => {
    const value = e.target.value;
    setName((prevName) => {
      return { value, msg: prevName.msg, valid: prevName.valid };
    });
    if (!validator.isName(e.target.value)) {
      setName((prevName) => {
        return { value: prevName.value, msg: "Invalid Name", valid: false };
      });
    } else {
      setEdited(true);
      setName((prevName) => {
        return { value: prevName.value, msg: "", valid: true };
      });
    }
  };

  const onAddressChange = (e: any) => {
    const value = e.target.value;
    setAddress((prevAddress) => {
      return { value, msg: prevAddress.msg, valid: prevAddress.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setEdited(true);
      setAddress((prevAddress) => {
        return { value: prevAddress.value, msg: "", valid: true };
      });
    } else {
      setAddress((prevAddress) => {
        return {
          value: prevAddress.value,
          msg: "Invalid Address",
          valid: false,
        };
      });
    }
  };

  const onPhoneChange = (e: any) => {
    const value = e.target.value;
    setPhone((prevPhone) => {
      return { value, msg: prevPhone.msg, valid: prevPhone.valid };
    });
    if (validator.isMobileNum(e.target.value)) {
      setEdited(true);
      setPhone((prevPhone) => {
        return { value: prevPhone.value, msg: "", valid: true };
      });
    } else {
      setPhone((prevPhone) => {
        return {
          value: prevPhone.value,
          msg: "Invalid Phone Number",
          valid: false,
        };
      });
    }
  };

  const onGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setGender((prevGender) => {
      return { value, msg: prevGender.msg, valid: prevGender.valid };
    });
    if (!validator.isGender(e.target.value)) {
      setGender((prevGender) => {
        return { value: prevGender.value, msg: "Invalid Gender", valid: false };
      });
    } else {
      setEdited(true);
      setGender((prevGender) => {
        return { value: prevGender.value, msg: "", valid: true };
      });
    }
  };

  const onDobChange = (e: any) => {
    const value = e.target.value;
    const format = moment(value).format("YYYY-MM-DD");
    setDob((prevDob) => {
      return { value: format, msg: prevDob.msg, valid: prevDob.valid };
    });
    if (validator.isDob(format)) {
      setEdited(true);
      setDob((prevDob) => {
        return { value: prevDob.value, msg: "", valid: true };
      });
    } else {
      setDob((prevDob) => {
        return { value: prevDob.value, msg: "Invalid Dob", valid: false };
      });
    }
  };

  const onEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail((prevEmail) => {
      return { value, msg: prevEmail.msg, valid: prevEmail.valid };
    });
    if (validator.isEmail(e.target.value)) {
      setEdited(true);
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "", valid: true };
      });
    } else {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "Invalid Email", valid: false };
      });
    }
  };

  const onWeightChange = (e: any) => {
    const value: number = parseInt(e.target.value);
    setWeight((prevWeight) => {
      return { value, msg: prevWeight.msg, valid: prevWeight.valid };
    });
    if (validator.isWeight(e.target.value)) {
      setEdited(true);
      setWeight((prevWeight) => {
        return { value: prevWeight.value, msg: "", valid: true };
      });
    } else {
      setWeight((prevWeight) => {
        return { value: prevWeight.value, msg: "Invalid Weight", valid: false };
      });
    }
  };

  const onHeightChange = (e: any) => {
    const value: number = parseInt(e.target.value);
    setHeight((prevHeight) => {
      return { value, msg: prevHeight.msg, valid: prevHeight.valid };
    });
    if (validator.isHeight(e.target.value)) {
      setEdited(true);
      setHeight((prevHeight) => {
        return { value: prevHeight.value, msg: "", valid: true };
      });
    } else {
      setHeight((prevHeight) => {
        return { value: prevHeight.value, msg: "Invalid Height", valid: false };
      });
    }
  };

  const onDiseaseChange = (e: any) => {
    const value = e.target.value;
    setDisease((prevDisease) => {
      return { value, msg: prevDisease.msg, valid: prevDisease.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setEdited(true);
      setDisease((prevDisease) => {
        return { value: prevDisease.value, msg: "", valid: true };
      });
    } else {
      setDisease((prevDisease) => {
        return {
          value: prevDisease.value,
          msg: "Invalid Disease",
          valid: false,
        };
      });
    }
  };

  const onSubscriptionDateChange = (e: any) => {
    const value = e.target.value;
    setSubscriptionDate((prevSubscriptionDate) => {
      return {
        value,
        msg: prevSubscriptionDate.msg,
        valid: prevSubscriptionDate.valid,
      };
    });
    if (validator.isDob(e.target.value)) {
      setEdited(true);
      setSubscriptionDate((prevSubscriptionDate) => {
        return { value: prevSubscriptionDate.value, msg: "", valid: true };
      });
    } else {
      setSubscriptionDate((prevSubscriptionDate) => {
        return {
          value: prevSubscriptionDate.value,
          msg: "Invalid SubscriptionDate",
          valid: false,
        };
      });
    }
  };

  const onDisabilityChange = (e: any) => {
    const value = e.target.value;
    setDisability((prevDisability) => {
      return { value, msg: prevDisability.msg, valid: prevDisability.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setEdited(true);
      setDisability((prevDisability) => {
        return { value: prevDisability.value, msg: "", valid: true };
      });
    } else {
      setDisability((prevDisability) => {
        return {
          value: prevDisability.value,
          msg: "Invalid Disability",
          valid: false,
        };
      });
    }
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = e.target.files;
    const selectedFile: File | null = selectedFiles ? selectedFiles[0] : null;
    if (selectedFile && validator.isImage(selectedFile?.type)) {
      setEdited(true);
      setImageThumb(URL.createObjectURL(selectedFile));
      setSelectedImage((prevImage) => {
        return { value: selectedFile, msg: "", valid: true };
      });
    } else {
      setImageThumb("");
      setSelectedImage((prevImage) => {
        return {
          value: null,
          msg: "Wrong image format.!(please upload .jpeg or .png)",
          valid: false,
        };
      });
    }
  };

  const onSubmit = () => {
    if (!error) {
      const clientsData: object = {
        id_clients: dataItem.id_clients.toString(),
        image_client: selectedImage.value,
        id_packages: packageID.value.toString(),
        name: name.value,
        address: address.value,
        phone: phone.value,
        gender: gender.value,
        dob: dob.value,
        email: email.value,
        weight: weight.value.toString(),
        height: height.value.toString(),
        disability: disability.value,
        diseases: disease.value,
        subscription_date: moment(subscriptionDate.value).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        status: "true",
      };

      dispatch(clientsUpdateRequest(clientsData));
    } else {
      alert("wrong input");
    }
  };

  return (
    <>
      <PackageUpdateButtonContainer>
        <ButtonGreen onClick={() => onNewPackageModalShow(dataItem)}>
          <i className="bi bi-currency-rupee" />
          New Package
        </ButtonGreen>
      </PackageUpdateButtonContainer>

      <CustomInputFile
        onChange={onSelectFile}
        selectedImage={imagethumb}
        error={selectedImage.msg}
      />

      <ClickEditColumn>
        <ClickEditContent>
          <ClickPackageSelect
            label="Package"
            msg={packageID.msg}
            onChange={onPackageIdChange}
            value={packageID.value}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickEdit
            label="Name"
            value={name.value}
            onChange={onNameChange}
            type="text"
            msg={name.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      <ClickEditColumn>
        <ClickEditContent>
          <ClickEdit
            label="Address"
            value={address.value}
            onChange={onAddressChange}
            type="text"
            msg={address.msg}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickEdit
            label="Phone"
            value={phone.value}
            onChange={onPhoneChange}
            type="text"
            msg={phone.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      <ClickEditColumn>
        <ClickEditContent>
          <ClickSelect
            label="Gender"
            msg={gender.msg}
            selected={gender.value}
            options={genderOptions}
            onChange={onGenderChange}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickDate
            label="Birth Date"
            value={dob.value}
            onChange={onDobChange}
            msg={dob.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      <ClickEditColumn>
        <ClickEditContent>
          <ClickEdit
            label="Email"
            value={email.value}
            onChange={onEmailChange}
            type="email"
            msg={email.msg}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickEdit
            label="Height"
            value={height.value}
            onChange={onHeightChange}
            type="number"
            msg={height.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      <ClickEditColumn>
        <ClickEditContent>
          <ClickEdit
            label="Weight"
            value={weight.value}
            onChange={onWeightChange}
            type="number"
            msg={weight.msg}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickEdit
            label="Diseases"
            value={disease.value}
            onChange={onDiseaseChange}
            type="text"
            msg={disease.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      <ClickEditColumn>
        <ClickEditContent>
          <ClickEdit
            label="Disability"
            value={disability.value}
            onChange={onDisabilityChange}
            type="text"
            msg={disability.msg}
          />
        </ClickEditContent>

        <ClickEditContent>
          <ClickDate
            label="Subscription Date"
            value={subscriptionDate.value}
            onChange={onSubscriptionDateChange}
            msg={subscriptionDate.msg}
          />
        </ClickEditContent>
      </ClickEditColumn>

      {edited && !error && (
        <ClickEditSubmitContainer>
          <SubmitButton onClick={onSubmit}>Update Details</SubmitButton>
        </ClickEditSubmitContainer>
      )}
    </>
  );
};

export default UpdateClient;
