import React, { useState, useRef } from "react";
import {
  ClickEditContainer,
  ClickEditError,
  ClickEditInput,
  ClickEditLabel,
  ClickEditValue,
  ClickEditValueContainer,
} from "../../styled/forms/FormComponents";

interface EditTypes {
  type: string;
  value: any;
  onChange: (e: any) => void;
  label: string;
  msg: string;
}

const ClickEdit = ({ type, value, onChange, label, msg }: EditTypes) => {
  const [edit, setEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onClickEdit = () => {
    setEdit(true);
    inputRef.current?.focus();
  };

  return (
    <ClickEditContainer>
      <ClickEditLabel>{label}</ClickEditLabel>
      <ClickEditValueContainer>
        {!edit && (
          <ClickEditValue onClick={onClickEdit}>{value}</ClickEditValue>
        )}
        {edit && (
          <ClickEditInput
            type={type}
            placeholder={label}
            value={value}
            onBlur={() => setEdit(false)}
            ref={inputRef}
            onChange={onChange}
          />
        )}
        <i onClick={onClickEdit} className="bi bi-pencil-fill" />
      </ClickEditValueContainer>
      <ClickEditError>{msg}</ClickEditError>
    </ClickEditContainer>
  );
};

export default ClickEdit;
