import React, { useState } from "react";
import {
  ContainerArea,
  ContentArea,
  EmptyArea,
  SubHeadingText,
} from "../../views/styled/layout/CommonViews";
import FilterDropdown from "../../views/elements/filter/FilterDropdown";
import MessageToast from "../../views/elements/toast/MesageToast";
import LoadingModal from "../../views/elements/modals/LoadingModal";
import DeleteComponent from "../../views/elements/deleteButton/DeleteComponent";
import FilterAreaButton from "../../views/components/form/FilterAreaButton";
import ModalView from "../../views/elements/modals/ModalView";
import AddClient from "./components/AddClient";
import UpdateClient from "./components/UpdateClient";
import NewPackage from "./components/NewPackage";
import MessageBox from "./components/Message/MessageBox";
import MessageButton from "./components/Message/MessageButton";
import DataTable from "../components/table/DataTable/DataTable";
import {
  FilterItemContainer,
  FilterItemsContainer,
  FilterMenuBar,
  HeadingSection,
  PageHeading,
} from "../../views/styled/filterbar/FilterViews";
import SelectAllCheckbox from "../components/table/DataTable/components/SelectAllCheckBox";
import CustomSearchBar from "../components/search/CustomSearchBar";
import { SearchBarContainer } from "../../views/styled/search/SearchBarViews";
import BackButton from "../../views/components/goback/BackButton";

interface ClientsTypes {
  clientsData: Array<object | null>;
  isLoading: boolean;
  error: string;
  filterItems: Array<object>;
  filterState: [string, (param: string) => void];
  deleteState: [Array<number>];
  finalLoad: boolean;
  currentState: [number, (param: number) => void];
  limit: number;
  apiStatus: boolean;
  deleteClientsGroup: () => void;
  successMessage: string;
  clearMessages: () => void;
  selectAllHandle: (list: Array<number>) => void;
  addModalState: [boolean, (param: boolean) => void];
  addClients: (params: object) => void;
  updateModalState: [
    object,
    (param: any) => void,
    () => void,
    boolean,
    () => void,
    () => void
  ];
  newPackageModalState: [boolean, () => void];
  messageModalState: [boolean, () => void, (item: any) => void];
  onSearch: (value: string) => void;
}

export default function ClientsContainer({
  clientsData,
  isLoading,
  error,
  filterItems,
  filterState,
  deleteState,
  finalLoad,
  currentState,
  limit,
  apiStatus,
  deleteClientsGroup,
  successMessage,
  clearMessages,
  selectAllHandle,
  addModalState,
  updateModalState,
  newPackageModalState,
  messageModalState,
  onSearch,
}: ClientsTypes) {
  const [addModalVisible, setAddModalVisible] = addModalState;
  const [deleteItems] = deleteState;
  const [
    selectedItem,
    onClickItem,
    onNewPackageModalShow,
    clientsUpdateModalVisible,
    onUpdateModalClose,
    clearUpdate,
  ] = updateModalState;
  const [newPackageModalShow, onNewPackageModalClose] = newPackageModalState;
  const [messageModalShow, onMessageModalClose, onMessageModalShow] =
    messageModalState;
  return (
    <ContainerArea>
      <ModalView
        modalShow={addModalVisible}
        onHide={() => setAddModalVisible(false)}
        modalTitle={"Add Clients"}
      >
        <AddClient setModalVisible={setAddModalVisible} />
      </ModalView>

      <ModalView
        modalShow={clientsUpdateModalVisible}
        onHide={clearUpdate}
        modalTitle={"Update Client Details"}
      >
        <UpdateClient
          dataItem={selectedItem}
          onNewPackageModalShow={onNewPackageModalShow}
        />
      </ModalView>

      <ModalView
        modalShow={newPackageModalShow}
        onHide={clearUpdate}
        modalTitle={"Renew Package"}
      >
        <NewPackage dataItem={selectedItem} onPackageModalClose={clearUpdate} />
      </ModalView>

      <ModalView
        modalShow={messageModalShow}
        onHide={onMessageModalClose}
        modalTitle={"Send Message"}
      >
        <MessageBox selectedItem={selectedItem} />
      </ModalView>

      <FilterMenuBar>
        <BackButton />
        <HeadingSection>
          {deleteItems.length > 0 && (
            <SelectAllCheckbox
              tableData={clientsData}
              selectAllHandle={selectAllHandle}
              tableType={"clients"}
            />
          )}
          <PageHeading>Clients</PageHeading>
        </HeadingSection>

        <FilterItemsContainer>
          <FilterItemContainer>
            {deleteItems.length > 0 && (
              <DeleteComponent
                deleteDataGroup={deleteClientsGroup}
                deleteState={deleteState}
                tableData={clientsData}
                tableType="clients"
              />
            )}
          </FilterItemContainer>

          <FilterItemContainer>
            <FilterDropdown
              filterItems={filterItems}
              filterState={filterState}
            />
          </FilterItemContainer>

          <FilterItemContainer>
            <FilterAreaButton
              onClick={() => setAddModalVisible(true)}
              name={"Add Client"}
            />
          </FilterItemContainer>
        </FilterItemsContainer>
      </FilterMenuBar>
      <CustomSearchBar onSearch={onSearch} />

      {!clientsData.length && (
        <EmptyArea>
          <SubHeadingText>No Clients to show.</SubHeadingText>
        </EmptyArea>
      )}

      <LoadingModal isLoading={isLoading} />
      <ContentArea>
        {clientsData.length > 0 && (
          <DataTable
            tableData={clientsData}
            currentState={currentState}
            finalLoad={finalLoad}
            isLoading={isLoading}
            limit={limit}
            tableType={"clients"}
            apiStatus={apiStatus}
            onClickItem={onClickItem}
          />
        )}

        <MessageToast
          error={error}
          successMessage={successMessage}
          clearMessages={clearMessages}
        />

        {deleteItems.length === 1 && (
          <MessageButton
            onClickMessageButton={onMessageModalShow}
            dataItem={clientsData.find(
              (client: any) => client?.id_clients === deleteItems[0]
            )}
          />
        )}
      </ContentArea>
    </ContainerArea>
  );
}
