import React from "react";
import {
  ClickEditArea,
  ClickEditContainer,
  ClickEditError,
  ClickEditLabel,
  ClickEditValueContainer,
} from "../../styled/forms/FormComponents";

interface EditTypes {
  value: any;
  onChange: (e: any) => void;
  label: string;
  msg: string;
}

const ClickEditTextArea = ({ value, onChange, label, msg }: EditTypes) => {
  return (
    <ClickEditContainer>
      <ClickEditLabel>{label}</ClickEditLabel>
      <ClickEditValueContainer>
        <ClickEditArea placeholder={label} value={value} onChange={onChange} />
      </ClickEditValueContainer>
      <ClickEditError>{msg}</ClickEditError>
    </ClickEditContainer>
  );
};

export default ClickEditTextArea;
