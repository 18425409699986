import React, { useState } from "react";
import {
  CustomCheckBox,
  SelectAllCheckBoxContainer,
} from "../../../../../views/styled/forms/FormComponents";

interface SelectorTypes {
  tableData: Array<object | null>;
  selectAllHandle: (list: Array<number>) => void;
  tableType: string;
}

const SelectAllCheckbox = ({
  tableData,
  selectAllHandle,
  tableType,
}: SelectorTypes) => {
  const [check, setCheck] = useState(false);
  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const selectArray: Array<number> = [];

      tableData.forEach((elem: any) => {
        const dataId =
          tableType === "clients" ? elem.id_clients : elem.id_packages;
        selectArray.push(dataId);
      });

      selectAllHandle(selectArray);
      setCheck(true);
    } else {
      const selectArray: Array<number> = [];
      selectAllHandle(selectArray);
      setCheck(false);
    }
  };
  return (
    <SelectAllCheckBoxContainer>
      <CustomCheckBox type="checkbox" checked={check} onChange={onSelect} />
    </SelectAllCheckBoxContainer>
  );
};

export default SelectAllCheckbox;
