import React from "react";
import EndPoints from "../../../api/EndPoints";
import { useAppSelector } from "../../../redux/hooks/hooks";
import { appColors } from "../../colours/colours";
import { CustomLogoContainer } from "../../styled/navbar/NavBarViews";

interface LogoPropTypes {
  width: number;
  height: number;
  imageThumb: string;
}

export default function CustomLogo({
  width,
  height,
  imageThumb,
}: LogoPropTypes) {
  return (
    <>
      {imageThumb ? (
        <img
          src={imageThumb}
          width={width}
          height={height}
          style={{ borderRadius: width / 2 }}
        />
      ) : (
        <i
          className="bi bi-person-circle"
          style={{ fontSize: width, color: appColors.grey }}
        />
      )}
    </>
  );
}
