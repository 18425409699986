import { NativeFormControlElement } from "@mui/base/FormControlUnstyled/FormControlUnstyled.types";
import React from "react";
import { Form } from "react-bootstrap";
interface inputTypes {
  label: string | null;
  msg: string | null;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<object>;
}

const InputSelect = ({ options, onChange, label, msg }: inputTypes) => {
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>{label}</Form.Label>
      <Form.Select onChange={onChange}>
        <option value={"no-value-1"}>{label}</option>
        {options.map((option: any, key: number) => (
          <option key={key} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      <Form.Text className="text-danger">{msg}</Form.Text>
    </Form.Group>
  );
};

export default InputSelect;
