import { NativeFormControlElement } from "@mui/base/FormControlUnstyled/FormControlUnstyled.types";
import React from "react";
import { Form } from "react-bootstrap";
interface inputTypes {
  label: string | null;
  placeholder: string;
  msg: string | null;
  value: any | null;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: any) => void;
}

const InputBox = ({
  type,
  onChange,
  onBlur,
  label,
  placeholder,
  msg,
  value,
}: inputTypes) => {
  return (
    <Form.Group className="mb-3" controlId={"formBasicEmail" + label}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
      />
      <Form.Text className="text-danger">{msg}</Form.Text>
    </Form.Group>
  );
};

export default InputBox;
