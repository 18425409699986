import React, { useState, useEffect } from "react";
import LoginContainer from "./LoginContainer";
import { clearMessages, loginRequest } from "../../redux/slices/LoginSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import validator from "../../utils/validator/validator";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formError, setFormError] = useState(true);
  const [email, setEmail] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [password, setPassword] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const login = useAppSelector((state: any) => state?.login);
  const { error } = login;

  useEffect(() => {
    if (email.valid && password.valid) {
      setFormError(false);
    } else {
      setFormError(true);
    }
  }, [email, password]);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail((prevEmail) => {
      return { value, msg: prevEmail.msg, valid: prevEmail.valid };
    });
    if (validator.isEmail(e.target.value)) {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "", valid: true };
      });
    } else {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "Invalid Email", valid: false };
      });
    }
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword((prevPassword) => {
      return { value, msg: prevPassword.msg, valid: prevPassword.valid };
    });
    if (validator.isStrongPassword(e.target.value)) {
      setPassword((prevPassword) => {
        return { value: prevPassword.value, msg: "", valid: true };
      });
    } else {
      setPassword((prevPassword) => {
        return {
          value: prevPassword.value,
          msg: "Invalid Password",
          valid: false,
        };
      });
    }
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  const onSubmit = () => {
    interface userDataTypes {
      email: string;
      password: string;
    }
    const userData: userDataTypes = {
      email: email.value,
      password: password.value,
    };
    dispatch(loginRequest({ params: userData, navigate }));
  };

  return (
    <LoginContainer
      email={email}
      password={password}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onSubmit={onSubmit}
      error={error}
      formError={formError}
      clearMessages={clearAllMessages}
    />
  );
}
