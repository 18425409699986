import React from "react";
import {
  ContainerArea,
  ContentArea,
  EmptyArea,
  SubHeadingText,
} from "../../views/styled/layout/CommonViews";
import DeleteComponent from "../../views/elements/deleteButton/DeleteComponent";
import FilterAreaButton from "../../views/components/form/FilterAreaButton";
import LoadingModal from "../../views/elements/modals/LoadingModal";
import MessageToast from "../../views/elements/toast/MesageToast";
import ModalView from "../../views/elements/modals/ModalView";
import AddPackage from "./components/AddPackage";
import UpdatePackage from "./components/UpdatePackage";
import DataTable from "../components/table/DataTable/DataTable";
import {
  FilterItemContainer,
  FilterItemsContainer,
  FilterMenuBar,
  HeadingSection,
  PageHeading,
} from "../../views/styled/filterbar/FilterViews";
import SelectAllCheckbox from "../components/table/DataTable/components/SelectAllCheckBox";
import BackButton from "../../views/components/goback/BackButton";

interface PackageTypes {
  packagesData: Array<object | null>;
  isLoading: boolean;
  error: string;
  deleteState: [Array<number>];
  finalLoad: boolean;
  currentState: [number, (param: number) => void];
  limit: number;
  apiStatus: boolean;
  deletePackagesGroup: () => void;
  successMessage: string;
  clearMessages: () => void;
  selectAllHandle: (list: Array<number>) => void;
  addModalState: [boolean, (param: boolean) => void];
  addPackages: (params: object) => void;
  updateModalState: [
    object,
    (param: any) => void,
    boolean,
    () => void,
    () => void
  ];
}

export default function PackageContainer({
  packagesData,
  isLoading,
  error,
  deleteState,
  finalLoad,
  currentState,
  limit,
  apiStatus,
  deletePackagesGroup,
  successMessage,
  clearMessages,
  selectAllHandle,
  addModalState,
  addPackages,
  updateModalState,
}: PackageTypes) {
  const [addModalVisible, setAddModalVisible] = addModalState;
  const [deleteItems] = deleteState;
  const [
    selectedItem,
    onClickItem,
    packagesUpdateModalVisible,
    onUpdateModalClose,
    clearUpdate,
  ] = updateModalState;
  return (
    <ContainerArea>
      <ModalView
        modalShow={addModalVisible}
        onHide={() => setAddModalVisible(false)}
        modalTitle={"Add Packages"}
      >
        <AddPackage
          addPackages={addPackages}
          setModalVisible={setAddModalVisible}
        />
      </ModalView>

      <ModalView
        modalShow={packagesUpdateModalVisible}
        onHide={clearUpdate}
        modalTitle={"Update Client Details"}
      >
        <UpdatePackage dataItem={selectedItem} />
      </ModalView>

      <FilterMenuBar>
        <BackButton />
        <HeadingSection>
          {deleteItems.length > 0 && (
            <SelectAllCheckbox
              tableData={packagesData}
              selectAllHandle={selectAllHandle}
              tableType={"packages"}
            />
          )}
          <PageHeading>Packages</PageHeading>
        </HeadingSection>

        <FilterItemsContainer>
          <FilterItemContainer>
            {deleteItems.length > 0 && (
              <DeleteComponent
                deleteDataGroup={deletePackagesGroup}
                deleteState={deleteState}
                tableData={packagesData}
                tableType="packages"
              />
            )}
          </FilterItemContainer>

          <FilterItemContainer>
            <FilterAreaButton
              onClick={() => setAddModalVisible(true)}
              name={"Add Package"}
            />
          </FilterItemContainer>
        </FilterItemsContainer>
      </FilterMenuBar>

      {!packagesData.length && (
        <EmptyArea>
          <SubHeadingText>
            No Packages to show. Please add Packages.
          </SubHeadingText>
        </EmptyArea>
      )}

      <LoadingModal isLoading={isLoading} />

      <ContentArea>
        {packagesData.length > 0 && (
          <DataTable
            tableData={packagesData}
            currentState={currentState}
            finalLoad={finalLoad}
            isLoading={isLoading}
            limit={limit}
            tableType={"packages"}
            apiStatus={apiStatus}
            onClickItem={onClickItem}
          />
        )}

        <MessageToast
          error={error}
          successMessage={successMessage}
          clearMessages={clearMessages}
        />
      </ContentArea>
    </ContainerArea>
  );
}
