import React, { useEffect, useState } from "react";
import validator from "../../../utils/validator/validator";
import {
  ButtonGreen,
  ButtonRed,
  SubmitButton,
  SubmitButtonContainer,
  SubmitContainer,
} from "../../../views/styled/forms/FormComponents";
import InputBox from "../../../views/elements/input/InputBox";

interface AddPackageTypes {
  addPackages: (params: object) => void;
  setModalVisible: (state: boolean) => void;
}

const AddPackage = ({ addPackages, setModalVisible }: AddPackageTypes) => {
  const [error, setError] = useState(true);

  const [name, setName] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const [validity, setValidity] = useState({
    value: 0,
    msg: "",
    valid: false,
  });

  const [amount, setAmount] = useState({
    value: 0,
    msg: "",
    valid: false,
  });

  const [description, setDescription] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    if (name.valid && validity.valid && amount.valid && description.valid) {
      setError(false);
    } else {
      setError(true);
    }
  }, [name, validity, amount, description]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName((prevName) => {
      return { value, msg: prevName.msg, valid: prevName.valid };
    });
    if (validator.specialCharChecker(value)) {
      setName((prevName) => {
        return { value: prevName.value, msg: "Invalid Name", valid: false };
      });
    } else {
      setName((prevName) => {
        return { value: prevName.value, msg: "", valid: true };
      });
    }
  };

  const onValidityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setValidity((prevvalidity) => {
      return { value, msg: prevvalidity.msg, valid: prevvalidity.valid };
    });
    if (validator.isValidity(e.target.value)) {
      setValidity((prevvalidity) => {
        return { value: prevvalidity.value, msg: "", valid: true };
      });
    } else {
      setValidity((prevvalidity) => {
        return {
          value: prevvalidity.value,
          msg: "Invalid validity.!",
          valid: false,
        };
      });
    }
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setAmount((prevAmount) => {
      return { value, msg: prevAmount.msg, valid: prevAmount.valid };
    });
    if (validator.isAmount(e.target.value)) {
      setAmount((prevAmount) => {
        return { value: prevAmount.value, msg: "", valid: true };
      });
    } else {
      setAmount((prevAmount) => {
        return {
          value: prevAmount.value,
          msg: "Invalid Amount.!",
          valid: false,
        };
      });
    }
  };
  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDescription((prevDescription) => {
      return { value, msg: prevDescription.msg, valid: prevDescription.valid };
    });
    if (!validator.specialCharChecker(value)) {
      setDescription((prevDescription) => {
        return { value: prevDescription.value, msg: "", valid: true };
      });
    } else {
      setDescription((prevDescription) => {
        return {
          value: prevDescription.value,
          msg: "Invalid Description.!",
          valid: false,
        };
      });
    }
  };

  const clearForm = () => {
    setName({
      value: "",
      msg: "",
      valid: false,
    });
    setDescription({
      value: "",
      msg: "",
      valid: false,
    });

    setValidity({
      value: 0,
      msg: "",
      valid: false,
    });
    setAmount({
      value: 0,
      msg: "",
      valid: false,
    });
  };

  const onSubmit = () => {
    if (!error) {
      const clientsData: object = {
        name: name.value,
        validity: validity.value.toString(),
        amount: amount.value.toString(),
        description: description.value,
        status: "true",
      };

      addPackages(clientsData);
    } else {
      alert("wrong input");
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <InputBox
        type="text"
        label="Name"
        msg={name.msg}
        value={name.value}
        placeholder="Package Name"
        onChange={onNameChange}
        onBlur={onNameChange}
      />

      <InputBox
        type="number"
        label="Validity (in days )"
        msg={validity.msg}
        value={validity.value}
        placeholder="Enter Package ID"
        onChange={onValidityChange}
        onBlur={onValidityChange}
      />

      <InputBox
        type="number"
        label="Amount ( in rupees )"
        msg={amount.msg}
        value={amount.value}
        placeholder="Enter Amount"
        onChange={onAmountChange}
        onBlur={onAmountChange}
      />

      <InputBox
        type="text"
        label="Description"
        msg={description.msg}
        value={description.value}
        placeholder="Enter Description"
        onChange={onDescriptionChange}
        onBlur={onDescriptionChange}
      />

      <SubmitContainer>
        <SubmitButtonContainer>
          <SubmitButton onClick={() => onSubmit()} disabled={error}>
            Add Package
          </SubmitButton>
        </SubmitButtonContainer>

        <SubmitButtonContainer>
          <ButtonGreen
            onClick={() => {
              clearForm();
            }}
          >
            Clear
          </ButtonGreen>
        </SubmitButtonContainer>

        <SubmitButtonContainer>
          <ButtonRed
            onClick={() => {
              clearForm();
              setModalVisible(false);
            }}
          >
            Close
          </ButtonRed>
        </SubmitButtonContainer>
      </SubmitContainer>
    </form>
  );
};

export default AddPackage;
