import React, { useEffect, useState } from "react";
import { TabTextArea } from "../../../views/styled/message/MessagesViews";
import {
  SubmitButton,
  SubmitButtonContainer,
} from "../../../views/styled/forms/FormComponents";
import { useAppSelector } from "../../../redux/hooks/hooks";
import moment from "moment";
import validator from "../../../utils/validator/validator";
import SendToWhatsapp from "../../../utils/Api/SendToWhatsapp";
import HeaderGenerate from "../../../utils/Api/HeaderGenerate";
import EndPoints from "../../../api/EndPoints";
import { FileDownloader } from "../../../api/FileDownloader";

interface PaymentTypes {
  onPaymentSuccess: any;
  dataItem: any;
  setIsLoading: (param: boolean) => void;
  setSuccessMessage: (param: string) => void;
  setFormError: (param: string) => void;
}

const PaymentSuccess = ({
  onPaymentSuccess,
  dataItem,
  setIsLoading,
  setSuccessMessage,
  setFormError,
}: PaymentTypes) => {
  const gyms = useAppSelector((state) => state.gyms);
  interface gymtypes {
    gymMessages: any;
  }
  interface messageTypes {
    gymMessages: any;
    userDetails: any;
  }
  const { gymMessages, userDetails }: messageTypes = gyms;

  const formatMessages = (message: string) => {
    const formattedEndDate = moment(
      onPaymentSuccess?.data?.subscription_end_date
    ).format("DD-MMMM-YYYY");
    const formattedSubscribedDate = moment(
      onPaymentSuccess?.data?.subscribed_date
    ).format("DD-MMMM-YYYY");

    let formattedMessage = message?.replace("_gym_name_", userDetails?.name);
    formattedMessage = formattedMessage.replace(
      "_subscription_end_",
      formattedEndDate
    );
    formattedMessage = formattedMessage.replace(
      "_client_name_",
      onPaymentSuccess?.data?.client_name
    );
    formattedMessage = formattedMessage.replace(
      "_subscribed_date_",
      formattedSubscribedDate
    );
    formattedMessage = formattedMessage.replace(
      "_package_name_",
      onPaymentSuccess?.data?.package_name
    );
    return formattedMessage;
  };

  const [messageFormError, setMesageFormError] = useState(false);
  const [subscribedPackage, setSubscribedPackage] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setSubscribedPackage((prevPackageId) => {
      return { value: gymMessages?.subscribed_package, msg: "", valid: true };
    });
  };

  useEffect(() => {
    setSubscribedPackage((prevPackageId) => {
      return {
        value: formatMessages(gymMessages?.subscribed_package),
        msg: "",
        valid: true,
      };
    });
  }, [onPaymentSuccess]);

  useEffect(() => {
    if (subscribedPackage.valid) {
      setMesageFormError(false);
    } else {
      setMesageFormError(true);
    }
  }, [subscribedPackage]);

  const onSubscribedPackageChange = (e: any) => {
    const value = e.target.value;
    setSubscribedPackage((prevSubscribedPackage) => {
      return {
        value,
        msg: prevSubscribedPackage.msg,
        valid: prevSubscribedPackage.valid,
      };
    });
    if (validator.specialCharChecker(e.target.value)) {
      setSubscribedPackage((prevSubscribedPackage) => {
        return {
          value: prevSubscribedPackage.value,
          msg: "Invalid Pending Message",
          valid: false,
        };
      });
    } else {
      setSubscribedPackage((prevSubscribedPackage) => {
        return { value: prevSubscribedPackage.value, msg: "", valid: true };
      });
    }
  };

  const onMessageSend = () => {
    const { phone } = dataItem;
    SendToWhatsapp(phone, subscribedPackage.value);
  };

  const onGeneratePdf = async () => {
    const id_payments = onPaymentSuccess?.data.id_payments;
    setIsLoading(true);
    const headers = HeaderGenerate();
    try {
      const subscriptionsDataResponse: any = await FileDownloader(
        EndPoints.paymentsInvoice + id_payments,
        "GET",
        {},
        headers
      );
      setIsLoading(false);
      setSuccessMessage("Pdf generated succesfully.!");
    } catch (e: any) {
      setIsLoading(false);
      setFormError(e?.response?.data?.error);
    }
  };

  return (
    <>
      <TabTextArea
        name="postContent"
        value={subscribedPackage.value}
        onChange={onSubscribedPackageChange}
      />
      <SubmitButtonContainer>
        <SubmitButton onClick={onMessageSend} disabled={messageFormError}>
          <i className="bi bi-whatsapp" /> Send to Whatsapp
        </SubmitButton>

        <SubmitButton onClick={onGeneratePdf}>
          <i className="bi bi-filetype-pdf" /> Download Invoice
        </SubmitButton>
      </SubmitButtonContainer>
    </>
  );
};

export default PaymentSuccess;
