import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const DeleteButtonContainer = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.button`
  position: relative;
  box-shadow: 0 0 10px #ddd;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${appColors.white};
  border: none;
  i {
    font-size: 16px;
  }
  .deleteCount {
    font-size: 5px;
    line-height: 5px;
    position: absolute;
    color: ${appColors.black};
  }
`;

export const DeleteDatatable = styled.table``;

export const DeleteThead = styled.thead`
  box-shadow: 0px 2px 10px ${appColors.grey};
`;
export const DeleteTbody = styled.tbody``;

export const DeleteTr = styled.tr`
  box-shadow: 0px 2px 10px ${appColors.grey};
`;

export const DeleteTd = styled.td`
  padding: 10px 10px;
`;

export const DeleteTh = styled.th`
  padding: 10px 10px;
`;
