import React, { useEffect, useState, useRef, useCallback } from "react";
import DownloadButton from "./components/DownloadButton";
import { useAppDispatch } from "../../../../redux/hooks/hooks";
import { debounce } from "../../../../utils/debouncer/debouncer";
import {
  TableArea,
  TableMain,
  Tbody,
} from "../../../../views/styled/datatable/TableViews";
import { setLoading } from "../../../../redux/slices/SubscriptionsSlice";
import { ClientListContainer } from "../../../../views/styled/dataTable/DataTableViews";
import SubscriptionListElement from "./components/SubscriptionListElement";

interface DatatableTypes {
  tableData: Array<object | null>;
  currentState: [number, (param: number) => void];
  finalLoad: boolean;
  isLoading: boolean;
  limit: number;
  tableType: string;
  apiStatus: boolean;
}
const DataListTable = ({
  tableData,
  currentState,
  finalLoad,
  isLoading,
  limit,
  tableType,
  apiStatus,
}: DatatableTypes) => {
  const [current, setCurrent] = currentState;
  const [intercepted, setIntercepted] = useState(true);
  const [toggleIntersection, setToggleIntersection] = useState(0);
  const dispatch = useAppDispatch();
  const observer = useRef<any>(null);

  useEffect(() => {
    !finalLoad && setIntercepted(false);
  }, [tableData]);

  useEffect(() => {
    if (!finalLoad && !isLoading && !intercepted && !apiStatus) {
      setIntercepted(true);
      setCurrent(current + limit);
    }
  }, [toggleIntersection]);

  const onInterception = () => {
    setToggleIntersection(Date.now());
  };

  const debounceInterception = debounce(onInterception, 500);

  const lastItemRef = useCallback(
    (node: any) => {
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          debounceInterception();
        }
      });
      if (node) observer?.current?.observe(node);
    },
    [finalLoad]
  );

  const onSetLoading = (state: boolean) => {
    dispatch(setLoading(state));
  };

  return (
    <>
      <TableArea>
        <TableMain>
          <Tbody>
            <ClientListContainer>
              {tableData.map((item: any, key: number) => {
                return (
                  <SubscriptionListElement
                    item={item}
                    keyId={key}
                    listLength={tableData.length}
                    lastItemRef={lastItemRef}
                    setLoading={onSetLoading}
                  />
                );
              })}
            </ClientListContainer>
          </Tbody>
        </TableMain>
      </TableArea>
    </>
  );
};

export default DataListTable;
