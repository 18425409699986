import axios from "axios";
import fileDownload from "js-file-download";
export const FileDownloader = async (
  url: string,
  method: string,
  params?: object,
  headerParams?: object
) => {
  interface optionTypes {
    method?: string;
    headers?: object;
    url?: string;
    data?: object;
    responseType: any;
  }

  const options: optionTypes = {
    method,
    headers: headerParams,
    url,
    data: params,
    responseType: "blob",
  };

  return new Promise((resolve: any, reject: any) => {
    axios(options)
      .then(function (response) {
        fileDownload(response.data, "invoice.pdf");
        resolve({
          status: true,
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
