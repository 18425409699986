import React from "react";
import {
  TileHeading,
  TileIcon,
  TileIconContainer,
  TileItem,
} from "../../../views/styled/dashboard/DashBoardView";

interface TileTypes {
  icon: string;
  tileHeading: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}
export default function Tile({ icon, tileHeading, onClick }: TileTypes) {
  return (
    <TileItem onClick={onClick}>
      <div>
        <TileIconContainer>
          <TileIcon className={icon} />
        </TileIconContainer>
        <TileHeading>{tileHeading}</TileHeading>
      </div>
    </TileItem>
  );
}
