import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinners from "../spinner/Spinners";
interface LoadingModaltypes {
  isLoading: boolean;
}
export default function LoadingModal({ isLoading }: LoadingModaltypes) {
  return (
    <Modal
      show={isLoading}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spinners type="circle" />
    </Modal>
  );
}
