import React, { useEffect, useRef, useState } from "react";
import PackageContainer from "./PackageContainer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import {
  clearMessages,
  packagesDataRequest,
  packagesDeleteRequest,
  setCurrentReducer,
  setDeleteItemsReducer,
  setAddModalVisibleReducer,
  packagesAddRequest,
  setPackagesUpdateModalVisibleReducer,
} from "../../redux/slices/PackagesSlice";
const Package = () => {
  const firstUpdate = useRef(true);
  const dispatch = useAppDispatch();
  const packages = useAppSelector((state) => state.packages);
  const [selectedItem, setSelectedItem] = useState({});

  const {
    packagesData,
    isLoading,
    error,
    finalLoad,
    limit,
    apiStatus,
    successMessage,
    currentPosition,
    deleteItems,
    filterShadow,
    addModalVisible,
    packagesUpdateModalVisible,
  } = packages;

  useEffect(() => {
    if (!firstUpdate.current && !filterShadow) {
      getPackagesData();
    }
  }, [currentPosition]);

  useEffect(() => {
    if (firstUpdate.current) {
      getPackagesData();
      firstUpdate.current = false;
    }
  }, []);

  const setCurrent = (param: number) => {
    dispatch(setCurrentReducer(param));
  };
  const setDeleteItems = (param: Array<number>) => {
    dispatch(setDeleteItemsReducer(param));
  };
  const setAddModalVisible = (param: boolean) => {
    dispatch(setAddModalVisibleReducer(param));
  };

  const getPackagesData = () => {
    const urlParams = `/${currentPosition}/${limit}`;
    dispatch(packagesDataRequest(urlParams));
  };

  const selectAllHandle = (list: Array<number>) => {
    setDeleteItems(list);
  };

  const deletePackagesGroup = () => {
    if (deleteItems.length > 0) {
      const params: object = {
        ids: deleteItems.toString(),
      };
      dispatch(packagesDeleteRequest(params));
    }
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  const addPackage = (params: object) => {
    dispatch(packagesAddRequest(params));
  };

  // Update section

  const clearUpdate = () => {
    dispatch(setPackagesUpdateModalVisibleReducer(false));
    setSelectedItem({});
  };

  const onClickItem = (item: any) => {
    setSelectedItem(item);
    dispatch(setPackagesUpdateModalVisibleReducer(true));
  };

  const onUpdateModalClose = () => {
    dispatch(setPackagesUpdateModalVisibleReducer(false));
  };

  // update section end

  return (
    <div>
      <PackageContainer
        packagesData={packagesData}
        isLoading={isLoading}
        error={error}
        deleteState={[deleteItems]}
        finalLoad={finalLoad}
        currentState={[currentPosition, setCurrent]}
        limit={limit}
        apiStatus={apiStatus}
        deletePackagesGroup={deletePackagesGroup}
        successMessage={successMessage}
        clearMessages={clearAllMessages}
        selectAllHandle={selectAllHandle}
        addModalState={[addModalVisible, setAddModalVisible]}
        addPackages={addPackage}
        updateModalState={[
          selectedItem,
          onClickItem,
          packagesUpdateModalVisible,
          onUpdateModalClose,
          clearUpdate,
        ]}
      />
    </div>
  );
};

export default Package;
