import React, { useEffect, useState } from "react";
import { SearchBarContainer } from "../../../views/styled/search/SearchBarViews";
import CustomSearchInput from "../../../views/components/search/CustomSearchInput";
interface Searchtypes {
  onSearch: (value: string) => void;
}
const CustomSearchBar = ({ onSearch }: Searchtypes) => {
  const [scrolled, setScrolled] = useState(false);
  let prevScroll = 0;
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function onScroll() {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos < prevScroll) {
      prevScroll = currentScrollPos;
      if (currentScrollPos > 90) {
        setScrolled(false);
      }
    } else {
      prevScroll = currentScrollPos;
      setScrolled(true);
    }
  }

  return (
    <SearchBarContainer style={{ top: scrolled ? -15 : 40 }}>
      <CustomSearchInput onSearch={onSearch} />
    </SearchBarContainer>
  );
};

export default CustomSearchBar;
