import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const SearchBarContainer = styled.div`
  width: 100%;
  padding: 20px 5% 0px 5%;
  position: sticky;
  z-index: 1;
  transition: top 0.3s;

  @media screen and (min-width: 390px) {
    padding: 20px 8% 0px 8%;
  }
  @media screen and (min-width: 480px) {
    padding: 20px 10% 0px 10%;
  }

  @media screen and (min-width: 720px) {
    padding: 20px 20% 0px 20%;
  }
`;

export const SearchItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled.input`
  width: 50%;
  height: 30px;
  padding: 5px 10px;
  margin: 0;
  border-radius: 10px 0px 0px 10px;
  border-style: solid none solid solid;

  @media screen and (min-width: 390px) {
    width: 60%;
  }
  @media screen and (min-width: 480px) {
    width: 70%;
  }

  @media screen and (min-width: 720px) {
    width: 80%;
  }
`;

export const SearchButton = styled.button`
  width: 20%;
  height: 30px;
  border-radius: 0px 10px 10px 0px;
  border-style: solid solid solid none;
  background: ${appColors.grey};

  @media screen and (min-width: 390px) {
    width: 16%;
  }
  @media screen and (min-width: 480px) {
    width: 15%;
  }

  @media screen and (min-width: 720px) {
    width: 10%;
  }
`;

export const SearchIcon = styled.i`
  font-size: 16px;
  color: ${appColors.white};
  font-weight: 800;
`;
