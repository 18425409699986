import Ls from "../localStorage/Ls";
const HeaderGenerate = (hasFile?: boolean | null) => {
  const loginJson: any = Ls.getItem("login");
  const { isLoggedIn, key } = loginJson;
  const headers = {
    Accept: "application/json",
    "Content-Type": hasFile ? "multipart/form-data" : "application/json",
    Authorization: isLoggedIn ? key : null,
  };
  return headers;
};

export default HeaderGenerate;
