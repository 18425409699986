import React from "react";
import {
  Container,
  GeoLocationBox,
  GeoLocationBoxContainer,
  HeaderContainer,
  HorizontalRule,
  LoginFormContainer,
  WelcomeText,
} from "../../views/styled/login/LoginFormView";
import InputBox from "../../views/elements/input/InputBox";
import CustomLogo from "../../views/components/logo/CustomLogo";
import {
  SubmitButton,
  SubmitButtonContainer,
  SubmitContainer,
} from "../../views/styled/forms/FormComponents";
import MessageToast from "../../views/elements/toast/MesageToast";
import CustomInputFile from "../../views/components/form/fileUploader/CustomInputFile";

interface SignUpTypes {
  emailState: [any, (param: any) => void];
  nameState: [any, (param: any) => void];
  passwordState: [any, (param: any) => void];
  locationState: [any, (param: any) => void];
  addressState: [any, (param: any) => void];
  phoneState: [any, (param: any) => void];
  altPhoneState: [any, (param: any) => void];
  subscriptionState: [any, (param: any) => void];
  fileState: [any, (param: any) => void];
  formError: boolean;
  error: string;
  clearMessages: () => void;
  onSubmit: () => void;
  selectedImage: any;
}

export default function SignupContainer({
  emailState,
  nameState,
  passwordState,
  locationState,
  addressState,
  phoneState,
  altPhoneState,
  subscriptionState,
  fileState,
  selectedImage,
  formError,
  error,
  onSubmit,
  clearMessages,
}: SignUpTypes) {
  const [email, onEmailChange] = emailState;
  const [name, onNameChange] = nameState;
  const [password, onPasswordChange] = passwordState;
  const [location, onLocationChange] = locationState;
  const [address, onAddressChange] = addressState;
  const [phone, onPhoneChange] = phoneState;
  const [altPhone, onAltPhoneChange] = altPhoneState;
  const [subscription, onSubscriptionChange] = subscriptionState;
  const [imagethumb, onSelectFile] = fileState;

  const SignupFormView = () => {
    return (
      <LoginFormContainer>
        <HeaderContainer>
          <WelcomeText>Sign Up</WelcomeText>
        </HeaderContainer>
        <HeaderContainer>
          <HorizontalRule />
        </HeaderContainer>
        <CustomInputFile
          onChange={onSelectFile}
          selectedImage={imagethumb}
          error={selectedImage.msg}
        />

        <InputBox
          type="text"
          label="Gym Name"
          msg={name.msg}
          value={name.value}
          placeholder="Enter Gym Name"
          onChange={onNameChange}
          onBlur={onNameChange}
        />

        <InputBox
          type="email"
          label="Email"
          msg={email.msg}
          value={email.value}
          placeholder="Enter Email"
          onChange={onEmailChange}
          onBlur={onEmailChange}
        />

        <InputBox
          type="text"
          label="Gym Address"
          msg={address.msg}
          value={address.value}
          placeholder="Enter Gym Address"
          onChange={onAddressChange}
          onBlur={onAddressChange}
        />

        <InputBox
          type="text"
          label="Gym Phone"
          msg={phone.msg}
          value={phone.value}
          placeholder="Enter Gym Phone"
          onChange={onPhoneChange}
          onBlur={onPhoneChange}
        />

        <InputBox
          type="text"
          label="Gym Alternate phone"
          msg={altPhone.msg}
          value={altPhone.value}
          placeholder="Enter Alternate phone number"
          onChange={onAltPhoneChange}
          onBlur={onAltPhoneChange}
        />

        <InputBox
          type="password"
          label="Password"
          msg={password.msg}
          value={password.value}
          placeholder="Enter Password"
          onChange={onPasswordChange}
          onBlur={onPasswordChange}
        />

        <SubmitContainer>
          <SubmitButtonContainer>
            <SubmitButton onClick={() => onSubmit()} disabled={formError}>
              Sign Up
            </SubmitButton>
          </SubmitButtonContainer>
        </SubmitContainer>
      </LoginFormContainer>
    );
  };

  return (
    <Container>
      {location.valid ? (
        SignupFormView()
      ) : (
        <GeoLocationBoxContainer>
          <GeoLocationBox>
            <i className="bi bi-geo-alt" />
            <p>{location.msg}</p>
            <p>Please allow location access and reload this page</p>
          </GeoLocationBox>
        </GeoLocationBoxContainer>
      )}
      <MessageToast
        error={error}
        successMessage={""}
        clearMessages={clearMessages}
      />
    </Container>
  );
}
