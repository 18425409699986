import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

interface CanvasTypes {
  show: boolean;
  onHide: () => void;
  children: string | JSX.Element | JSX.Element[];
}

const OffCanvasView = ({ show, onHide, children }: CanvasTypes) => {
  return (
    <Offcanvas show={show} onHide={onHide}>
      {children}
    </Offcanvas>
  );
};

export default OffCanvasView;
