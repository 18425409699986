import React, { useEffect, useState, useRef, useCallback } from "react";
import { debounce } from "../../../../utils/debouncer/debouncer";
import {
  TableArea,
  TableMain,
  Tbody,
} from "../../../../views/styled/datatable/TableViews";
import PackageListElement from "./components/PackageListElement";
import ClientsListElement from "./components/ClientsListElement";
import { ClientListContainer } from "../../../../views/styled/dataTable/DataTableViews";
interface DatatableTypes {
  tableData: Array<object | null>;
  currentState: [number, (param: number) => void];
  finalLoad: boolean;
  isLoading: boolean;
  limit: number;
  tableType: string;
  apiStatus: boolean;
  onClickItem: (item: any) => void;
}
const DataTable = ({
  tableData,
  currentState,
  finalLoad,
  isLoading,
  limit,
  tableType,
  apiStatus,
  onClickItem,
}: DatatableTypes) => {
  const [current, setCurrent] = currentState;
  const [intercepted, setIntercepted] = useState(true);
  const [toggleIntersection, setToggleIntersection] = useState(0);
  const observer = useRef<any>(null);

  useEffect(() => {
    !finalLoad && setIntercepted(false);
  }, [tableData]);

  useEffect(() => {
    if (!finalLoad && !isLoading && !intercepted && !apiStatus) {
      setIntercepted(true);
      setCurrent(current + limit);
    }
  }, [toggleIntersection]);

  const onInterception = () => {
    setToggleIntersection(Date.now());
  };

  const debounceInterception = debounce(onInterception, 500);

  const lastItemRef = useCallback(
    (node: any) => {
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          debounceInterception();
        }
      });
      if (node) observer?.current?.observe(node);
    },
    [finalLoad]
  );

  return (
    <>
      <TableArea>
        <TableMain>
          <Tbody>
            <ClientListContainer>
              {tableData.map((item: any, key: number) => {
                return (
                  <>
                    {tableType === "packages" ? (
                      <PackageListElement
                        item={item}
                        keyId={key}
                        listLength={tableData.length}
                        lastItemRef={lastItemRef}
                        onClickItem={onClickItem}
                      />
                    ) : (
                      <ClientsListElement
                        item={item}
                        keyId={key}
                        listLength={tableData.length}
                        lastItemRef={lastItemRef}
                        onClickItem={onClickItem}
                      />
                    )}
                  </>
                );
              })}
            </ClientListContainer>
          </Tbody>
        </TableMain>
      </TableArea>
    </>
  );
};

export default DataTable;
