import React from "react";
import {
  CustomLogoContainer,
  NavAppHeading,
  NavBarContainer,
  NavButton,
  NavButtonContainer,
  NavButtonIcon,
  NavHeadingSection,
} from "../../../views/styled/navbar/NavBarViews";
import CustomLogo from "../../../views/components/logo/CustomLogo";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import EndPoints from "../../../api/EndPoints";
import { setSideBarShowReducer } from "../../../redux/slices/AppUtilSlice";
function AppBar() {
  const dispatch = useAppDispatch();
  const gyms = useAppSelector((state) => state.gyms);
  interface gymtypes {
    userDetails: any;
  }
  const { userDetails }: gymtypes = gyms;

  const imageThumb = userDetails?.image_gyms
    ? EndPoints.gymImageUrl + userDetails?.image_gyms
    : "";

  const onSHowSideBar = () => {
    dispatch(setSideBarShowReducer(true));
  };

  return (
    <NavBarContainer>
      <NavButtonContainer>
        {imageThumb ? (
          <CustomLogoContainer onClick={onSHowSideBar}>
            <CustomLogo width={30} height={30} imageThumb={imageThumb} />
          </CustomLogoContainer>
        ) : (
          <NavButton onClick={onSHowSideBar}>
            <NavButtonIcon className="bi bi-list" />
          </NavButton>
        )}
      </NavButtonContainer>

      <NavHeadingSection>
        <NavAppHeading>{userDetails?.name}</NavAppHeading>
      </NavHeadingSection>
    </NavBarContainer>
  );
}

export default AppBar;
