import React from "react";
import {
  DataListImage,
  DataListImageContainer,
} from "../../../../../views/styled/dataTable/DataTableViews";
import EndPoints from "../../../../../api/EndPoints";
import imageMen from "../../.././../../../assets/images/user-men.png";
import imageWoman from "../../.././../../../assets/images/user-woman.png";
interface ListImageTypes {
  imageSrc: string;
  gender: string;
}

const DatalistThumb = ({ imageSrc, gender }: ListImageTypes) => {
  return (
    <DataListImageContainer>
      {imageSrc === "" ? (
        <DataListImage src={gender === "male" ? imageMen : imageWoman} />
      ) : (
        <DataListImage src={EndPoints.clientImageUrl + imageSrc} />
      )}
    </DataListImageContainer>
  );
};

export default DatalistThumb;
