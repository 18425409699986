import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiCaller } from "../../api/ApiCaller";
import endPoint from "../../api/EndPoints";
import HeaderGenerate from "../../utils/Api/HeaderGenerate";
import { logout } from "./LoginSlice";

interface ClientsType {
  isLoading: boolean;
  clientsData: Array<object>;
  error: string;
  finalLoad: boolean;
  limit: number;
  apiStatus: boolean;
  successMessage: string;
  currentPosition: number;
  filter: string;
  deleteItems: Array<number>;
  filterShadow: boolean;
  addModalVisible: boolean;
  clientsUpdateModalVisible: boolean;
  search: string;
}

const initialState: ClientsType = {
  isLoading: false,
  clientsData: [],
  error: "",
  finalLoad: false,
  limit: 20,
  apiStatus: false,
  successMessage: "",
  currentPosition: 0,
  filter: "name",
  deleteItems: [],
  filterShadow: false,
  addModalVisible: false,
  clientsUpdateModalVisible: false,
  search: "",
};

export const clientsDataRequest = createAsyncThunk(
  "CLIENTS_DATA_REQUEST",
  async (params: string, thunkAPI: any) => {
    thunkAPI.dispatch(setLoadingReducer(true));
    thunkAPI.dispatch(setApiStatus());
    try {
      const headers = HeaderGenerate();
      const clientsDataResponse: any = await apiCaller(
        endPoint.clients + params,
        "GET",
        {},
        headers
      );
      return clientsDataResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const clientsDeleteRequest = createAsyncThunk(
  "CLIENTS_DELETE_REQUEST",
  async (params: object, thunkAPI: any) => {
    thunkAPI.dispatch(setLoadingReducer(true));
    try {
      const headers = HeaderGenerate();
      const clientsDeleteResponse: any = await apiCaller(
        endPoint.deleteClients,
        "POST",
        params,
        headers
      );
      const response = clientsDeleteResponse.data;
      const deleteData: object = { response, params };
      return deleteData;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const clientsAddRequest = createAsyncThunk(
  "CLIENTS_ADD_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoadingReducer(true));
    try {
      const hasImage = params?.image_client;
      const headers = HeaderGenerate(hasImage);
      const destination = params?.image_client
        ? endPoint.clientsWithImage
        : endPoint.clients;
      const clientsAddResponse: any = await apiCaller(
        destination,
        "POST",
        params,
        headers
      );
      return clientsAddResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const clientsUpdateRequest = createAsyncThunk(
  "CLIENTS_UPDATE_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoadingReducer(true));
    try {
      const hasImage = params?.image_client;
      const headers = HeaderGenerate(hasImage);
      const destination = params?.image_client
        ? endPoint.clientsWithImage
        : endPoint.clients;
      const clientsUpdateResponse: any = await apiCaller(
        destination,
        "PUT",
        params,
        headers
      );
      return clientsUpdateResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const clientsSlice = createSlice({
  name: "Clients",
  initialState,
  reducers: {
    clear: (state) => {
      state.isLoading = false;
      state.clientsData = [];
      state.error = "";
      state.finalLoad = false;
    },
    clearDeleteItems: (state) => {
      state.deleteItems = [];
    },
    setCurrentReducer: (state, action) => {
      state.currentPosition = action.payload;
    },
    setFilterReducer: (state, action) => {
      state.filter = action.payload;
    },
    setSearchReducer: (state, action) => {
      state.search = action.payload;
    },
    setFilterShadow: (state) => {
      state.filterShadow = true;
    },
    setDeleteItemsReducer: (state, action) => {
      state.deleteItems = action.payload;
    },
    deleteListCreateReducer: (state, action) => {
      const { deleteItems } = current(state);
      const clientId = action.payload;
      let tempItems: Array<number> = [...deleteItems];
      if (deleteItems.find((deleteItem: number) => deleteItem === clientId)) {
        const itemId = deleteItems.findIndex(
          (deleteItem: number) => deleteItem === clientId
        );
        tempItems.splice(itemId, 1);
      } else {
        tempItems = [...deleteItems, clientId];
      }
      state.deleteItems = tempItems;
    },
    clearMessages: (state) => {
      state.error = "";
      state.successMessage = "";
    },
    setErrorReducer: (state, action) => {
      state.error = action.payload;
    },
    setSuccessMessageReducer: (state, action) => {
      state.successMessage = action.payload;
    },
    setLoadingReducer: (state, action) => {
      state.isLoading = action.payload;
    },
    setApiStatus: (state) => {
      state.apiStatus = true;
    },
    setAddModalVisibleReducer: (state, action) => {
      state.addModalVisible = action.payload;
    },
    setClientsUpdateModalVisibleReducer: (state, action) => {
      state.clientsUpdateModalVisible = action.payload;
    },
  },
  extraReducers: {
    [clientsDataRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [clientsDataRequest.fulfilled.type]: (state, action) => {
      if (action?.payload?.data.length > 0) {
        const { clientsData, limit } = current(state);
        if (action?.payload?.data.length < limit) {
          state.finalLoad = true;
        } else {
          state.finalLoad = false;
        }
        state.apiStatus = false;
        state.isLoading = false;
        const responseData = action?.payload?.data;
        state.clientsData = [...clientsData, ...responseData];
        state.error = "";
        state.filterShadow = false;
      } else {
        state.apiStatus = false;
        state.isLoading = false;
        state.error = "";
        state.finalLoad = true;
        state.filterShadow = false;
      }
    },
    [clientsDataRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.filterShadow = false;
      state.error = action.payload?.data?.error;
    },

    [clientsDeleteRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [clientsDeleteRequest.fulfilled.type]: (state, action) => {
      const { clientsData } = current(state);
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.deleteItems = [];
      state.successMessage = action?.payload?.response?.message;
      const deletedIds = action?.payload?.params?.ids.split(/,/);
      const removedClientsdata = clientsData.filter(
        (client: any) =>
          !deletedIds.find(
            (id: any) => parseInt(id) === parseInt(client.id_clients)
          )
      );
      state.clientsData = removedClientsdata;
    },
    [clientsDeleteRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },

    [clientsAddRequest.pending.type]: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [clientsAddRequest.fulfilled.type]: (state, action) => {
      const { clientsData } = current(state);
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.successMessage = "Client Added Successfully.!";
      state.clientsData = [...clientsData, action?.payload?.data];
    },
    [clientsAddRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },

    [clientsUpdateRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [clientsUpdateRequest.fulfilled.type]: (state, action) => {
      const { clientsData } = current(state);
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.successMessage = "Client Details Changed Successfully.!";
      const prevClientsData = [...clientsData];
      const editedData = action?.payload?.data;
      const editedIndex = prevClientsData.findIndex(
        (client: any) =>
          parseInt(client.id_clients) === parseInt(editedData.id_clients)
      );
      prevClientsData[editedIndex] = editedData;
      state.clientsData = prevClientsData;
      state.clientsUpdateModalVisible = false;
    },
    [clientsUpdateRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },
  },
});

export const {
  clear,
  setLoadingReducer,
  setApiStatus,
  clearMessages,
  setCurrentReducer,
  setFilterReducer,
  setDeleteItemsReducer,
  deleteListCreateReducer,
  setFilterShadow,
  clearDeleteItems,
  setAddModalVisibleReducer,
  setClientsUpdateModalVisibleReducer,
  setErrorReducer,
  setSuccessMessageReducer,
  setSearchReducer,
} = clientsSlice.actions;

export const clientsState = (state: RootState) => state.clients;

export default clientsSlice.reducer;
