import React from "react";
import { TbodyItem } from "../../../../../views/styled/datatable/TableViews";
import SelectorCheckbox from "./SelectorCheckbox";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hooks/hooks";
import { deleteListCreateReducer } from "../../../../../redux/slices/ClientsSlice";
import DatalistThumb from "./DataListThumb";
import moment from "moment";
import dateCompare from "../../../../../utils/date/dateCompare";
import {
  BirthdayIcon,
  ClientIconArea,
  ClientListAddressArea,
  ClientListDetailsArea,
  ClientListName,
  ClientListNameArea,
  ClientListPanel,
  ClientListPersonalArea,
  DetailIcon,
  DetailText,
  IconDetailsArea,
  TbodyRowClient,
} from "../../../../../views/styled/dataTable/DataTableViews";

interface ClientsTypes {
  item: any;
  keyId: number;
  listLength: number;
  lastItemRef: any;
  onClickItem: (item: any) => void;
}
const ClientsListElement = ({
  item,
  keyId,
  listLength,
  lastItemRef,
  onClickItem,
}: ClientsTypes) => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state.clients);
  const { finalLoad, deleteItems } = clients;
  const deleteListCreate = (clientId: number) => {
    dispatch(deleteListCreateReducer(clientId));
  };

  const birthday = dateCompare.isBirthday(item?.dob);

  return (
    <TbodyRowClient
      key={keyId}
      ref={listLength === keyId + 1 && !finalLoad ? lastItemRef : null}
      className={listLength === keyId + 1 ? "last-one" : "normal"}
    >
      <TbodyItem>
        <span>
          <SelectorCheckbox
            dataId={parseInt(item.id_clients)}
            deleteListCreate={deleteListCreate}
            checked={
              deleteItems.find(
                (deleteItem) => deleteItem === parseInt(item.id_clients)
              )
                ? true
                : false
            }
          />
        </span>
      </TbodyItem>

      <TbodyItem
        onClick={() => {
          onClickItem(item);
        }}
      >
        <ClientListPanel>
          <ClientListPersonalArea>
            <DatalistThumb
              imageSrc={item.image_clients}
              gender={item?.gender}
            />
            <ClientListNameArea>
              <ClientListName>{item?.name}</ClientListName>
              <IconDetailsArea>
                <DetailIcon className="bi bi-bag-heart" />
                <DetailText>
                  {moment(item?.subscription_end_date).format("DD-MM-YYYY")}
                </DetailText>
              </IconDetailsArea>
            </ClientListNameArea>
          </ClientListPersonalArea>

          <ClientListDetailsArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi bi-phone" />
              <DetailText>{item?.phone}</DetailText>
            </IconDetailsArea>

            <IconDetailsArea>
              <DetailIcon className="bi bi-envelope-at" />
              <DetailText>{item?.email}</DetailText>
            </IconDetailsArea>
          </ClientListDetailsArea>

          <ClientListAddressArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi-pin-map" />
              <DetailText>{item?.address}</DetailText>
            </IconDetailsArea>
          </ClientListAddressArea>

          {birthday && (
            <ClientIconArea>
              <BirthdayIcon className="bi bi-gift" />
            </ClientIconArea>
          )}
        </ClientListPanel>
      </TbodyItem>
    </TbodyRowClient>
  );
};

export default ClientsListElement;
