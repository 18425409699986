import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";
import { HeadingText } from "../layout/CommonViews";

export const FilterMenuBar = styled.div`
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
  border-bottom: 1px solid ${appColors.grey};
  box-shadow: 0 0 10px #ddd;
  background-color: #fff;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  margin: 0;
  @media screen and (min-width: 720px) {
    padding: 10px 50px;
  }
`;

export const HeadingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PageHeading = styled(HeadingText)`
  font-size: 18px;
  color: ${appColors.darkGrey};
`;

export const FilterItemsContainer = styled.div`
  width: 100%;
  display: flex;
  grid-template-columns: auto auto auto;
  justify-content: end;
`;

export const FilterItemContainer = styled.div`
  padding-left: 10px;
`;

export const FilterButtonText = styled.span`
  padding-left: 5px;
  color: ${appColors.black};
  display: none;
  @media screen and (min-width: 390px) {
    display: inherit;
    font-size: 12px;
  }
  @media screen and (min-width: 480px) {
    font-size: 14px;
  }

  @media screen and (min-width: 720px) {
    font-size: 16px;
  }
`;

export const FilterButtonContainer = styled.div`
  background: ${appColors.grey};
  display: flex;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 14px;
  @media screen and (min-width: 390px) {
    height: 22px;
    min-width: 22px;
    border-radius: 11px;
  }
  @media screen and (min-width: 480px) {
    height: 24px;
    min-width: 24px;
    border-radius: 12px;
  }

  @media screen and (min-width: 720px) {
    height: 26px;
    min-width: 26px;
    border-radius: 13px;
  }

  .nav-item {
    font-size: 12px;
    @media screen and (min-width: 390px) {
      display: inherit;
      font-size: 12px;
    }
    @media screen and (min-width: 480px) {
      font-size: 14px;
    }

    @media screen and (min-width: 720px) {
      font-size: 16px;
    }
  }
  i {
    font-size: 12px;
    @media screen and (min-width: 480px) {
      font-size: 14px;
    }
    @media screen and (min-width: 720px) {
      font-size: 16px;
    }
  }
`;

export const FilterButton = styled.button`
  background: ${appColors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 14px;
  @media screen and (min-width: 390px) {
    height: 22px;
    min-width: 22px;
    border-radius: 11px;
  }
  @media screen and (min-width: 480px) {
    height: 24px;
    min-width: 24px;
    border-radius: 12px;
  }

  @media screen and (min-width: 720px) {
    height: 26px;
    min-width: 26px;
    border-radius: 13px;
  }
  i {
    font-size: 12px;
    @media screen and (min-width: 480px) {
      font-size: 14px;
    }

    @media screen and (min-width: 720px) {
      font-size: 16px;
    }
  }
`;

export const BackButtonContainer = styled.button`
  position: absolute;
  left: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${appColors.white};
  @media screen and (min-width: 720px) {
    left: 30px;
  }
`;

export const BackButtonIcon = styled.i`
  font-size: 16px;
  color: ${appColors.darkGrey};
  font-weight: bold;

  @media screen and (min-width: 720px) {
    font-size: 20px;
  }
`;
