import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components/macro";
import { SpinnerContainer } from "../../styled/layout/CommonViews";

interface SpinnersTypes {
  type: string;
}

const Spinners = ({ type }: SpinnersTypes) => {
  return (
    <SpinnerContainer>
      <Spinner
        animation={type === "circle" ? "border" : "grow"}
        variant="dark"
      />
    </SpinnerContainer>
  );
};

export default Spinners;
