const GetLocation = async () => {
  if (navigator.geolocation) {
    let locationObj = {};

    const mylocation = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          locationObj = {
            locationAccess: true,
            msg: "",
            location:
              position?.coords?.latitude + "," + position?.coords?.longitude,
          };
          resolve(locationObj);
        },
        (error: any) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              resolve({
                locationAccess: false,
                msg: "User denied the request for Geolocation.",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              resolve({
                locationAccess: false,
                msg: "Location information is unavailable.",
              });
              break;
            case error.TIMEOUT:
              resolve({
                locationAccess: false,
                msg: "The request to get user location timed out.",
              });
              break;
            case error.UNKNOWN_ERROR:
              resolve({
                locationAccess: false,
                msg: "An unknown error occurred.",
              });
              break;
          }
        }
      );
    });

    const location = mylocation.then((loc) => {
      return loc;
    });

    return location;
  } else {
    return {
      locationAccess: false,
      msg: "Geolocation is not supported by this browser.",
    };
  }
};

export default GetLocation;
