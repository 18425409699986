import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../slices/LoginSlice";
import clientsReducer from "../slices/ClientsSlice";
import PackagesSlice from "../slices/PackagesSlice";
import SubscriptionsSlice from "../slices/SubscriptionsSlice";
import GymSlice from "../slices/GymSlice";
import AppUtilSlice from "../slices/AppUtilSlice";
export const store = configureStore({
  reducer: {
    login: loginReducer,
    clients: clientsReducer,
    packages: PackagesSlice,
    subscriptions: SubscriptionsSlice,
    gyms: GymSlice,
    appUtils: AppUtilSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
