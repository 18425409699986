import React from "react";
import { Modal } from "react-bootstrap";

interface ModalTypes {
  modalShow: boolean;
  onHide: () => void;
  children: string | JSX.Element | JSX.Element[];
  modalTitle: string;
}

const ModalView = ({ modalShow, onHide, children, modalTitle }: ModalTypes) => {
  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {" "}
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalView;
