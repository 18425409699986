import React, { useRef, useState } from "react";
import {
  SearchButton,
  SearchIcon,
  SearchInput,
  SearchItemContainer,
} from "../../styled/search/SearchBarViews";
import validator from "../../../utils/validator/validator";

interface Searchtypes {
  onSearch: (value: string) => void;
}
const CustomSearchInput = ({ onSearch }: Searchtypes) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState("");
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      setInput(value);
      onSearch(value);
    }
    validator.isSearch(value) && setInput(value);
  };

  const onKeyPress = (e: any) => {
    const keyId = e.which;
    const keyName = e.key;
    if (keyId === 13 || keyName === "Enter") {
      onSearch(input);
    }
  };
  return (
    <SearchItemContainer>
      <SearchInput
        type="search"
        placeholder="Search..."
        ref={inputRef}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSearch(e.target.value)
        }
        onChange={onChangeInput}
        value={input}
        onKeyDown={onKeyPress}
      />
      <SearchButton>
        <SearchIcon
          onClick={() =>
            onSearch(inputRef?.current?.value ? inputRef?.current?.value : "")
          }
          className="bi bi-search"
        />
      </SearchButton>
    </SearchItemContainer>
  );
};

export default CustomSearchInput;
