import SecureLS from "secure-ls";

const ls = new SecureLS();

const setItem = (key: string, data: any) => {
  try {
    return ls.set(key, data);
  } catch (e) {
    return false;
  }
};

const getItem = (key: string) => {
  try {
    return JSON.parse(ls.get(key));
  } catch (e) {
    return false;
  }
};

const removeItem = (key: string) => {
  try {
    return ls.remove(key);
  } catch (e) {
    return false;
  }
};

export default { getItem, setItem, removeItem };
