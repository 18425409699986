import moment from "moment";
const isBirthday = (dob: string) => {
  const formatDob = moment(dob).format("YYYY-MM-DD");
  const dobParts = formatDob.split(/[- :]/);
  const dobMonth = dobParts[1];
  const dobDay = dobParts[2];

  const currentdate = new Date();
  const today = moment(currentdate).format("YYYY-MM-DD");
  const todayParts = today.split(/[- :]/);
  const todayMonth = todayParts[1];
  const todayDay = todayParts[2];

  if (todayMonth === dobMonth && todayDay === dobDay) {
    return true;
  } else {
    return false;
  }
};

const isSubscriptionEndDate = (subScriptionEndDate: string) => {
  const formatsubScriptionEndDate =
    moment(subScriptionEndDate).format("YYYY-MM-DD");
  const currentdate = new Date();
  const today = moment(currentdate).format("YYYY-MM-DD");
  const difference = moment(today).diff(formatsubScriptionEndDate);
  if (difference >= 0) {
    return true;
  } else {
    return false;
  }
};

const subscriptionEndDifference = (subScriptionEndDate: string) => {
  const formatsubScriptionEndDate =
    moment(subScriptionEndDate).format("YYYY-MM-DD");
  const currentdate = new Date();
  const today = moment(currentdate).format("YYYY-MM-DD");
  const difference = moment(today).diff(formatsubScriptionEndDate);
  return difference;
};

export default { isBirthday, isSubscriptionEndDate, subscriptionEndDifference };
