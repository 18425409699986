import React from "react";
import { Dropdown, NavDropdown } from "react-bootstrap";
import {
  FilterButtonContainer,
  FilterButtonText,
} from "../../styled/filterbar/FilterViews";
interface DropDownTypes {
  filterItems?: Array<object>;
  filterState: [string, (param: string) => void];
}

const FilterDropdown = ({ filterItems, filterState }: DropDownTypes) => {
  const [filter, setFilter] = filterState;
  const currentItem: any = filterItems?.find((item: any) => item.id === filter);
  return (
    <FilterButtonContainer>
      <NavDropdown
        id="nav-dropdown-dark-example"
        title={
          <span>
            <i className="bi bi-filter" />
            <FilterButtonText> {`${currentItem?.label} `} </FilterButtonText>
          </span>
        }
        menuVariant="dark"
      >
        {filterItems?.map((item: any, key) => {
          return (
            <span key={key}>
              <Dropdown.Item
                active={item.id === filter}
                onClick={(e) => setFilter(item.id)}
              >
                {item.label}
              </Dropdown.Item>
              <NavDropdown.Divider />
            </span>
          );
        })}
      </NavDropdown>
    </FilterButtonContainer>
  );
};

export default FilterDropdown;
