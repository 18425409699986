import { NativeFormControlElement } from "@mui/base/FormControlUnstyled/FormControlUnstyled.types";
import React from "react";
import {
  ClickEditContainer,
  ClickEditError,
  ClickEditLabel,
  ClickEditValueContainer,
  ClickSelectBox,
} from "../../styled/forms/FormComponents";
interface inputTypes {
  label: string | null;
  msg: string | null;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<object>;
  selected: string;
}

const ClickSelect = ({
  options,
  onChange,
  label,
  msg,
  selected,
}: inputTypes) => {
  return (
    <ClickEditContainer>
      <ClickEditLabel>{label}</ClickEditLabel>
      <ClickEditValueContainer>
        <ClickSelectBox onChange={onChange} value={selected}>
          <option value={"no-value-1"}>{label}</option>
          {options.map((option: any, key: number) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
        </ClickSelectBox>
      </ClickEditValueContainer>
      <ClickEditError>{msg}</ClickEditError>
    </ClickEditContainer>
  );
};

export default ClickSelect;
